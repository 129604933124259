import { navigate } from '../app-router';
import { ContentTitle } from '../pieces/reusable/content-title';
import { TopBar } from '../pieces/reusable/top-bar';
import { ModalForm } from '../pieces/reusable/modal-form';

export class EngagementDriverScreen {
    private container: HTMLElement | null = null;
    private modalForm: ModalForm;
    private topBar: TopBar;

    constructor() {
        this.modalForm = new ModalForm();
        this.topBar = new TopBar(this.modalForm);
    }

    public mount(container: HTMLElement) {
        this.container = container;
        this.container.innerHTML = '';
        this.container.className = 'min-h-screen';

        this.modalForm.mount(this.container);
        this.topBar.mount(this.container);

        const content = document.createElement('div');
        content.className = 'max-w-3xl mx-auto p-4 sm:p-8 mt-8';

        // Header
        const hero = document.createElement('div');
        hero.className = 'text-center mb-12';
        hero.innerHTML = `
            <h1 class="text-3xl sm:text-4xl font-semibold mb-3 bg-gradient-to-r from-purple-400 to-pink-400 text-transparent bg-clip-text">
                Become a PLATO5 Engagement Driver
            </h1>
            <p class="text-white/60 text-base max-w-xl mx-auto">
                Help shape our community and earn equity while creating meaningful interactions
            </p>
        `;

        const comingSoonBanner = document.createElement('div');
        comingSoonBanner.className = 'mb-10 px-6 py-4 bg-amber-500/20 border-2 border-amber-500/30 rounded-lg text-center';
        comingSoonBanner.innerHTML = `
            <div class="flex items-center justify-center gap-2">
                <i class="fas fa-clock text-amber-400 text-xl"></i>
                <span class="text-amber-400 font-medium text-lg">Program Launching Soon</span>
            </div>
            <p class="text-amber-400/80 text-sm mt-1">We're currently finalizing the details of our Engagement Driver program</p>
        `;

        // Requirements Section
        const requirements = document.createElement('div');
        requirements.className = 'mb-10';
        requirements.innerHTML = `
            <div class="bg-black/20 backdrop-blur-sm border border-white/10 rounded-lg p-6 hover:bg-black/30 transition-all">
                <div class="flex items-center gap-4 mb-4">
                    <div class="w-10 h-10 rounded-lg bg-purple-500/20 flex items-center justify-center">
                        <i class="fas fa-list-check text-purple-400 text-lg"></i>
                    </div>
                    <h3 class="text-lg font-medium text-white/90">Program Requirements</h3>
                </div>
                <ul class="space-y-3 text-sm text-white/60">
                    <li class="flex items-start gap-2">
                        <i class="fas fa-check text-purple-400/60 text-xs mt-1"></i>
                        <span>Post 20+ quality interactions weekly (Kommin Discussions, Icebreakers, or Nearby Events)</span>
                    </li>
                    <li class="flex items-start gap-2">
                        <i class="fas fa-check text-purple-400/60 text-xs mt-1"></i>
                        <span>Maintain an active PLATO Premier subscription</span>
                    </li>
                    <li class="flex items-start gap-2">
                        <i class="fas fa-check text-purple-400/60 text-xs mt-1"></i>
                        <span>Complete your PLATO5 profile</span>
                    </li>
                    <li class="flex items-start gap-2">
                        <i class="fas fa-check text-purple-400/60 text-xs mt-1"></i>
                        <span>Maintain good standing according to our Terms of Service</span>
                    </li>
                    <li class="flex items-start gap-2 text-amber-400/80">
                        <i class="fas fa-exclamation-triangle text-xs mt-1"></i>
                        <span>Low-effort posts may result in removal from the program</span>
                    </li>
                </ul>
            </div>
        `;

        // Equity Section
        const equitySection = document.createElement('div');
        equitySection.className = 'mb-10';
        equitySection.innerHTML = `
            <div class="bg-black/20 backdrop-blur-sm border border-white/10 rounded-lg p-6 hover:bg-black/30 transition-all">
                <div class="flex items-center gap-4 mb-4">
                    <div class="w-10 h-10 rounded-lg bg-emerald-500/20 flex items-center justify-center">
                        <i class="fas fa-chart-line text-emerald-400 text-lg"></i>
                    </div>
                    <h3 class="text-lg font-medium text-white/90">Equity Program (To Be Announced)</h3>
                </div>
                <div class="space-y-3 text-sm text-white/60">
                    <p>Our selective program will offer equity based on your engagement and impact in these areas:</p>
                    <ul class="space-y-2 ml-5">
                        <li class="list-disc">Community engagement metrics</li>
                        <li class="list-disc">Event participation and hosting</li>
                        <li class="list-disc">Content quality and consistency</li>
                    </ul>
                    <p class="text-sm italic mt-4">Full program details and equity structure to be announced</p>
                </div>
            </div>
        `;

        // Application Form
        const form = document.createElement('form');
        form.className = 'bg-black/20 backdrop-blur-sm border border-white/10 rounded-lg p-6';
        form.innerHTML = `
            <h2 class="text-lg font-medium text-white/90 mb-6">Apply to Join</h2>
            
            <div class="space-y-4">
                <div>
                    <label class="block text-white/60 text-sm mb-1">Full Name</label>
                    <input type="text" 
                        class="w-full px-3 py-2 rounded-lg bg-black/40 border border-white/10 text-white/90 focus:border-purple-500/50 focus:outline-none text-sm cursor-not-allowed" 
                        required
                        disabled
                    >
                </div>
                
                <div>
                    <label class="block text-white/60 text-sm mb-1">Email Address</label>
                    <input type="email" 
                        class="w-full px-3 py-2 rounded-lg bg-black/40 border border-white/10 text-white/90 focus:border-purple-500/50 focus:outline-none text-sm cursor-not-allowed" 
                        required
                        disabled
                    >
                </div>

                <div class="p-4 bg-black/40 rounded-lg">
                    <label class="flex items-start gap-3 cursor-not-allowed">
                        <input type="checkbox" class="mt-1 cursor-not-allowed" required disabled>
                        <span class="text-sm text-white/60">
                            I understand this role requires: maintaining PLATO Premier status, posting 20+ quality interactions weekly, 
                            having a completed profile, and maintaining good standing per TOS. I acknowledge that low-effort content may result 
                            in removal from the program.
                        </span>
                    </label>
                </div>

                <div>
                    <label class="block text-white/60 text-sm mb-1">Why are you interested in becoming an Engagement Driver?</label>
                    <textarea 
                        class="w-full px-3 py-2 rounded-lg bg-black/40 border border-white/10 text-white/90 focus:border-purple-500/50 focus:outline-none text-sm h-24 cursor-not-allowed" 
                        required
                        disabled
                    ></textarea>
                </div>

                <button type="submit" class="
                    w-full py-2 px-4
                    bg-gradient-to-r from-purple-500/50 to-pink-500/50
                    text-white/50 text-sm font-medium
                    rounded-lg
                    cursor-not-allowed
                    transition-all duration-200
                    flex items-center justify-center gap-2
                    mt-2
                " disabled>
                    <i class="fas fa-paper-plane"></i>
                    Coming Soon
                </button>
            </div>
        `;

        // Assemble the page
        content.appendChild(hero);
        content.appendChild(comingSoonBanner);
        content.appendChild(requirements);
        content.appendChild(equitySection);
        content.appendChild(form);
        
        this.container.appendChild(content);
    }

    public unmount(): void {
        if (this.container) {
            this.container.innerHTML = '';
        }
        this.container = null;
        this.topBar.unmount();
        this.modalForm.unmount();
    }
}