import { PlatoChatService } from '../../actions/platochat/api-services';
import { AuthService } from '../../actions/auth/api-services';
import { UINotifications } from '../../utilities/errors';
import { Hash } from '../../utilities/hash';
import { navigate } from '../../app-router';
import { ModalForm } from '../reusable/modal-form';

export class CustomNanoContent {
    private element: HTMLElement;
    private onComplete: (success: boolean) => void;
    private modalForm: ModalForm;
    private userId: number;

    constructor(onComplete: (success: boolean) => void) {
        this.element = document.createElement('div');
        this.onComplete = onComplete;
        this.modalForm = new ModalForm();
        this.userId = Number(localStorage.getItem('userId'));
        this.render();
    }

    private render(): void {
        this.element.className = 'space-y-4';
        this.element.innerHTML = `
            <h2 class="text-lg font-semibold text-white mb-4">Create a Custom Nano</h2>
            <p class="text-white/70 text-sm mb-4">Select 3 users to create a nano with. You will automatically be added as the fourth participant.</p>
            
            <div class="space-y-2 max-h-[50vh] overflow-y-auto pr-2 scrollbar-thin scrollbar-track-white/10 scrollbar-thumb-white/20">
                <div id="users-list" class="space-y-2">
                    Loading users...
                </div>
            </div>
            
            <div class="flex justify-between items-center mt-4 pt-4 border-t border-white/10">
                <span class="text-white/70 text-sm">Selected: <span id="selected-count">0</span>/3</span>
                <button id="create-btn" disabled
                    class="px-4 py-2 bg-blue-500/20 hover:bg-blue-500/30 rounded-lg
                    text-white/90 text-sm transition-all duration-300 disabled:opacity-50 disabled:cursor-not-allowed">
                    Create Nano
                </button>
            </div>
        `;

        this.initializeUserList();
    }

    private async initializeUserList() {
        const usersList = this.element.querySelector('#users-list');
        const selectedUsers = new Set<number>();
        const users = await AuthService.getUsers(0);

        if (usersList && users) {
            usersList.innerHTML = users
                .filter(user => user.id !== this.userId)
                .map(user => `
                    <div class="flex flex-col p-4 rounded-lg bg-white/5 hover:bg-white/10 transition-colors cursor-pointer space-y-3"
                        data-user-id="${user.id}">
                        <div class="flex items-center justify-between">
                            <div class="flex items-center gap-2">
                                <svg class="w-5 h-5 text-white/60" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" 
                                        d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                                </svg>
                                <span class="text-white font-medium">${user.name}</span>
                            </div>
                            <div class="w-5 h-5 rounded-full border-2 border-white/30 flex items-center justify-center transition-colors select-checkbox
                                ${selectedUsers.has(user.id) ? 'bg-blue-500 border-blue-500' : ''}">
                                ${selectedUsers.has(user.id) ? '<svg class="w-3 h-3 text-white" fill="currentColor" viewBox="0 0 20 20"><path d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"></path></svg>' : ''}
                            </div>
                        </div>
                    </div>
                `).join('');

            const updateUI = () => {
                const countEl = this.element.querySelector('#selected-count');
                const createBtn = this.element.querySelector('#create-btn');
                if (countEl) countEl.textContent = selectedUsers.size.toString();
                if (createBtn) (createBtn as HTMLButtonElement).disabled = selectedUsers.size !== 3;
            };

            usersList.addEventListener('click', (e) => {
                const userEl = (e.target as HTMLElement).closest('[data-user-id]');
                if (!userEl) return;

                const userId = parseInt(userEl.getAttribute('data-user-id') || '0');
                if (selectedUsers.has(userId)) {
                    selectedUsers.delete(userId);
                    const checkbox = userEl.querySelector('.select-checkbox');
                    if (checkbox) {
                        checkbox.classList.remove('bg-blue-500', 'border-blue-500');
                        checkbox.innerHTML = '';
                    }
                } else if (selectedUsers.size < 3) {
                    selectedUsers.add(userId);
                    const checkbox = userEl.querySelector('.select-checkbox');
                    if (checkbox) {
                        checkbox.classList.add('bg-blue-500', 'border-blue-500');
                        checkbox.innerHTML = '<svg class="w-3 h-3 text-white" fill="currentColor" viewBox="0 0 20 20"><path d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"></path></svg>';
                    }
                }
                updateUI();
            });

            const createBtn = this.element.querySelector('#create-btn');
            if (createBtn) {
                createBtn.addEventListener('click', async () => {
                    if (selectedUsers.size === 3) {
                        const userIds = Array.from(selectedUsers);
                        const nanoId = await PlatoChatService.createNano(
                            userIds[0],
                            userIds[1],
                            userIds[2],
                            this.userId
                        );
                        
                        this.modalForm.close();
                        
                        if (nanoId) {
                            const hashedNanoId = Hash.encodeId(nanoId);
                            navigate(`/platochat/nanos/${hashedNanoId}`);
                            this.onComplete(true);
                        } else {
                            UINotifications.shipErrorToUI('Failed to create custom nano');
                            this.onComplete(false);
                        }
                    }
                });
            }
        }
    }

    public getElement(): HTMLElement {
        return this.element;
    }
}