export class NearbyContent {
    private element: HTMLDivElement;

    constructor() {
        this.element = document.createElement('div');
        this.element.className = 'flex flex-col items-center space-y-6 p-8';

        // Calendar Icon
        const iconContainer = document.createElement('div');
        iconContainer.className = `
            w-32 
            h-32 
            bg-gradient-to-br 
            from-purple-500 
            to-indigo-600 
            rounded-2xl 
            flex 
            items-center 
            justify-center 
            shadow-lg 
            hover:shadow-purple-500/25 
            transition-all
            duration-300
        `;
        
        const icon = document.createElement('i');
        icon.className = 'fas fa-calendar-alt text-6xl text-white';
        iconContainer.appendChild(icon);

        // Message Container
        const messageBox = document.createElement('div');
        messageBox.className = `
            w-full 
            max-w-md 
            bg-white/5 
            backdrop-blur-sm 
            rounded-2xl 
            p-6 
            space-y-4
            border
            border-white/10
        `;
        
        const title = document.createElement('h2');
        title.className = 'text-2xl font-semibold text-white/90';
        title.textContent = 'Coming Soon!';
        
        const description = document.createElement('p');
        description.className = 'text-white/70 text-lg leading-relaxed';
        description.textContent = 'PLATO5 Nearby will connect you with local events and activities perfect for meeting up with your matches. Discover restaurants, shows, and experiences shared by businesses in your area.';
        
        const subtext = document.createElement('p');
        subtext.className = 'text-purple-400/90 text-base';
        subtext.textContent = 'In the meantime, explore our other features while we put the finishing touches on this service.';

        messageBox.append(title, description, subtext);
        this.element.append(iconContainer, messageBox);
    }

    public mount(parent: HTMLElement): void {
        parent.appendChild(this.element);
    }

    public unmount(): void {
        this.element.remove();
    }

    public getElement(): HTMLElement {
        return this.element;
    }
}