import { KomminService } from '../../actions/kommin/api-services';
import { ITagResponse } from '../../actions/kommin/models';
import { UINotifications } from '../../utilities/errors';
import { DiscussionsContent } from './discussions-content';

export class ITagContent {
    private element: HTMLDivElement;
    private tagsContainer: HTMLDivElement;
    private leftArrow: HTMLDivElement;
    private rightArrow: HTMLDivElement;
    private discussionsContent: DiscussionsContent;

    constructor(discussionsContent: DiscussionsContent) {
        this.element = document.createElement('div');
        this.element.className = 'relative flex items-center h-12';

        this.tagsContainer = document.createElement('div');
        this.tagsContainer.className = 'flex gap-2 overflow-x-auto pl-0 pr-10 no-scrollbar cursor-grab active:cursor-grabbing';
        this.element.appendChild(this.tagsContainer);

        this.leftArrow = this.createArrowButton('left') as HTMLDivElement;
        this.rightArrow = this.createArrowButton('right') as HTMLDivElement;
        
        this.element.insertBefore(this.leftArrow, this.tagsContainer);
        this.element.appendChild(this.rightArrow);

        this.discussionsContent = discussionsContent;
        this.setupScrollBehavior();
        this.populateITags();
        this.setupScrollWatcher();
    }

    private setupScrollBehavior() {
        let isDown = false;
        let startX: number;
        let scrollLeft: number;
        let velocity = 0;
        let lastX: number;
        let timestamp: number;
        let rafId: number;

        this.tagsContainer.addEventListener('mousedown', (e) => {
            isDown = true;
            startX = e.pageX;
            lastX = e.pageX;
            timestamp = Date.now();
            scrollLeft = this.tagsContainer.scrollLeft;
            this.tagsContainer.style.scrollBehavior = 'auto';
            cancelAnimationFrame(rafId);
        });

        this.tagsContainer.addEventListener('mouseleave', () => {
            if (isDown) {
                isDown = false;
                startMomentumScroll();
            }
        });

        this.tagsContainer.addEventListener('mouseup', () => {
            isDown = false;
            startMomentumScroll();
        });

        this.tagsContainer.addEventListener('mousemove', (e) => {
            if (!isDown) return;
            e.preventDefault();
            
            const dx = e.pageX - lastX;
            const dt = Date.now() - timestamp;
            velocity = dx / (dt || 1) * 20;
            
            this.tagsContainer.scrollLeft -= dx;
            
            lastX = e.pageX;
            timestamp = Date.now();
        });

        const startMomentumScroll = () => {
            if (Math.abs(velocity) < 0.5) return;
            
            this.tagsContainer.scrollLeft -= velocity;
            velocity *= 0.97;
            rafId = requestAnimationFrame(startMomentumScroll);
        };
    }

    private setupScrollWatcher() {
        // Initial check
        this.updateArrowVisibility();

        // Check on scroll
        this.tagsContainer.addEventListener('scroll', () => {
            this.updateArrowVisibility();
        });

        // Check on window resize
        window.addEventListener('resize', () => {
            this.updateArrowVisibility();
        });
    }

    private updateArrowVisibility() {
        const { scrollLeft, scrollWidth, clientWidth } = this.tagsContainer;
        
        // Hide left arrow if at the start
        if (scrollLeft <= 0) {
            this.leftArrow.style.display = 'none';
        } else {
            this.leftArrow.style.display = 'flex';
        }

        // Hide right arrow if at the end
        if (scrollLeft + clientWidth >= scrollWidth - 1) { // -1 for potential rounding
            this.rightArrow.style.display = 'none';
        } else {
            this.rightArrow.style.display = 'flex';
        }
    }

    private async populateITags() {
        try {
            const tags: ITagResponse[] | null = await KomminService.getDefaultTags();
            UINotifications.hideGlobalLoadingIndication();

            const mostRecentButton = this.createTagButton('Most Recent', 'black', () => {
                this.discussionsContent.loadMostRecentDiscussions();
                this.highlightTag(mostRecentButton);
            });
            this.tagsContainer.appendChild(mostRecentButton);

            if (tags) {
                tags.forEach(tag => {
                    const tagEl = this.createTagButton(tag.Tag, this.getVibrantColor(), () => {
                        this.discussionsContent.loadDiscussionsByITag(tag.Tag);
                        this.highlightTag(tagEl);
                    });
                    this.tagsContainer.appendChild(tagEl);
                });
            } else {
                console.error('Failed to load iTags');
            }

            // Force a layout recalculation after adding tags
            setTimeout(() => {
                this.updateArrowVisibility();
            }, 0);
        } catch (error) {
            console.error('Error loading iTags:', error);
            UINotifications.showGlobalLoadingIndication();
            const errorMessage = document.createElement('div');
            errorMessage.textContent = 'Failed to load tags';
            this.tagsContainer.appendChild(errorMessage);
        }
    }

    private createTagButton(text: string, color: string, onClick: () => void): HTMLButtonElement {
        const button = document.createElement('button');
        button.className = `
            shrink-0 px-4 py-1.5
            rounded-full
            font-medium
            text-sm
            text-white
            shadow-md
            transition-all duration-300
            hover:scale-103 active:scale-97
            snap-start
        `;
        button.textContent = text;
        button.style.backgroundColor = color;
        button.onclick = onClick;
        return button;
    }

    private highlightTag(selectedButton: HTMLButtonElement) {
        Array.from(this.tagsContainer.children).forEach(child => {
            if (child instanceof HTMLButtonElement) {
                child.style.border = '';
            }
        });
        selectedButton.style.border = '1.5px solid white';
    }

    private getVibrantColor(): string {
        const colors = [
            '#FF4B6E', // Deep pink
            '#7C3AED', // Vibrant purple
            '#2563EB', // Rich blue
            '#059669', // Deep emerald
            '#DC2626', // Vivid red
            '#0891B2', // Teal blue
            '#BE123C', // Crimson
            '#16A34A', // Forest green
            '#6D28D9', // Royal purple
            '#EA580C', // Burnt orange
            '#0369A1', // Ocean blue
            '#B91C1C', // Ruby red
            '#15803D', // Pine green
            '#9333EA', // Electric purple
            '#0D9488'  // Dark teal
        ];
        return colors[Math.floor(Math.random() * colors.length)];
    }

    private createArrowButton(direction: 'left' | 'right'): HTMLDivElement {
        const wrapper = document.createElement('div');
        wrapper.className = `
            absolute ${direction}-0 top-0 h-full
            flex items-center
            ${direction === 'left' ? 'bg-gradient-to-r' : 'bg-gradient-to-l'}
            from-black/20 to-transparent
            z-10
            px-2
        `;

        const button = document.createElement('button');
        button.className = `
            w-8 h-8
            flex items-center justify-center
            bg-black/40 hover:bg-black/60
            backdrop-blur-sm
            rounded-full
            text-white/80 hover:text-white
            transition-all duration-300
            shadow-lg
            border border-white/10
        `;
        button.innerHTML = `<i class="fa-solid fa-chevron-${direction} text-sm"></i>`;
        
        button.onclick = () => {
            const scrollAmount = direction === 'left' ? -200 : 200;
            this.tagsContainer.scrollBy({ left: scrollAmount, behavior: 'smooth' });
        };

        wrapper.appendChild(button);
        return wrapper;
    }

    public mount(parent: HTMLElement) {
        parent.appendChild(this.element);
    }

    public unmount(): void {
        this.element.remove();
    }
}