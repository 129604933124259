import { AuthService } from "../../actions/auth/api-services";
import { UserData } from '../../actions/auth/models';
import { ModalForm } from "../reusable/modal-form";
import { CustomNanoContent } from "../platochat/custom-nano-content";

export class UserFeed {
    private element: HTMLElement;
    private users: UserData[] = [];
    private favorites: any[] = [];

    constructor() {
        this.element = document.createElement('div');
        this.element.className = 'flex flex-col gap-8 p-4';
        this.initialize();
    }

    private async initialize() {
        try {
            // Fetch initial users
            const allUsers = await AuthService.getUsers(0);
            const currentUserId = localStorage.getItem('userId');
            
            // Filter out current user
            this.users = currentUserId 
                ? allUsers.filter(user => user.id !== parseInt(currentUserId))
                : allUsers;
            
            // Fetch favorites once
            if (currentUserId) {
                this.favorites = await AuthService.getFavorites(parseInt(currentUserId));
            }
            
            // Create welcome section
            const welcomeSection = this.createWelcomeSection();
            this.element.appendChild(welcomeSection);
            
            // Create user grid
            const userGrid = await this.createUserGrid();
            this.element.appendChild(userGrid);
        } catch (error) {
            console.error('Failed to initialize user feed:', error);
        }
    }

    private createWelcomeSection(): HTMLElement {
        const container = document.createElement('div');
        container.className = 'flex flex-col gap-4 mb-8';

        // Wrapper for responsive layout
        const wrapper = document.createElement('div');
        wrapper.className = 'flex flex-col items-center sm:flex-row sm:justify-between sm:items-center gap-4';

        // "New People to Meet" text with icon - left aligned
        const meetText = document.createElement('div');
        meetText.className = `
            flex items-center gap-2
            text-pink-400
            text-lg
            font-semibold
            font-['Inter']
            text-center
        `;
        
        const text = document.createElement('span');
        text.textContent = 'New People to Meet';
        
        const icon = document.createElement('i');
        icon.className = 'fa-solid fa-chevron-down';

        meetText.appendChild(text);
        meetText.appendChild(icon);

        wrapper.appendChild(meetText);

        // Only add Create Nano button if user is logged in
        const userId = localStorage.getItem('userId');
        if (userId) {
            const createNanoBtn = document.createElement('button');
            createNanoBtn.className = `
                inline-flex items-center gap-2
                px-4 py-2
                bg-gradient-to-r from-blue-600 to-purple-600 
                rounded-lg shadow-md 
                hover:shadow-lg 
                transition-all duration-200 
                text-white text-sm font-semibold
                border border-white/20
                self-center sm:self-start
            `;
            createNanoBtn.innerHTML = `
                <i class="fa-solid fa-plus"></i>
                <span>Create Nano</span>
            `;

            createNanoBtn.addEventListener('click', () => {
                const modalForm = new ModalForm();
                const customNanoContent = new CustomNanoContent((success: boolean) => {
                    modalForm.close();
                });
                modalForm.append(customNanoContent.getElement());
                modalForm.mount(document.body);
                modalForm.open();
            });

            wrapper.appendChild(createNanoBtn);
        }

        container.appendChild(wrapper);
        return container;
    }

    private async createUserGrid(): Promise<HTMLElement> {
        const column = document.createElement('div');
        column.className = 'flex flex-col gap-3 max-w-[600px] mx-auto w-full';

        for (const user of this.users) {
            const card = await this.createUserCard(user);
            column.appendChild(card);
        }

        return column;
    }

    private async createUserCard(user: UserData): Promise<HTMLElement> {
        const card = document.createElement('div');
        card.className = `
            flex flex-col
            bg-black/20 backdrop-blur-sm
            border border-white/10 rounded-lg
            overflow-hidden
            transition-all duration-300 
            hover:border-white/20
            hover:bg-black/30
            mb-3
        `;

        // User info section
        const info = document.createElement('div');
        info.className = 'p-5 flex flex-col gap-2';

        // Top row with name and favorite button
        const topRow = document.createElement('div');
        topRow.className = 'flex items-center justify-between';

        // Name (required)
        const name = document.createElement('h3');
        name.className = 'text-base font-semibold text-white/95 tracking-tight';
        name.textContent = user.name;

        topRow.appendChild(name);

        // Only add favorite button if user is logged in
        const userId = localStorage.getItem('userId');
        if (userId) {
            const isInitiallyFavorited = this.favorites.some(fav => fav.FavoriteID === user.id);
            
            const favoriteBtn = document.createElement('button');
            favoriteBtn.className = `
                flex items-center gap-1.5
                text-xs font-medium px-2 py-1 rounded
                text-white/40 hover:text-pink-400
                transition-all duration-200
                data-[selected=true]:bg-purple-600
                data-[selected=true]:text-white
                data-[selected=true]:backdrop-blur-sm
            `;
            favoriteBtn.innerHTML = `
                <i class="${isInitiallyFavorited ? 'fa-solid' : 'fa-regular'} fa-star"></i>
                <span>FAVORITE</span>
            `;

            if (isInitiallyFavorited) {
                favoriteBtn.dataset.selected = 'true';
            }

            favoriteBtn.addEventListener('click', async () => {
                const currentUserId = parseInt(userId);
                const isCurrentlyFavorited = favoriteBtn.dataset.selected === 'true';
                
                let success = false;
                if (isCurrentlyFavorited) {
                    success = await AuthService.deleteFavorite(currentUserId, user.id);
                } else {
                    success = await AuthService.createFavorite(currentUserId, user.id);
                }

                if (success) {
                    if (isCurrentlyFavorited) {
                        this.favorites = this.favorites.filter(f => f.FavoriteID !== user.id);
                        favoriteBtn.dataset.selected = 'false';
                        const starIcon = favoriteBtn.querySelector('i');
                        if (starIcon) starIcon.className = 'fa-regular fa-star';
                    } else {
                        this.favorites.push({ FavoriteID: user.id });
                        favoriteBtn.dataset.selected = 'true';
                        const starIcon = favoriteBtn.querySelector('i');
                        if (starIcon) starIcon.className = 'fa-solid fa-star';
                    }
                }
            });

            topRow.appendChild(favoriteBtn);
        }

        // Location (optional)
        const location = document.createElement('div');
        location.className = 'text-white/60 text-sm flex items-center gap-2';
        if (user.location) {
            location.innerHTML = `
                <i class="fa-solid fa-location-dot text-pink-400/70"></i>
                ${user.location}
            `;
        }

        // Intro (optional)
        const intro = document.createElement('p');
        intro.className = 'text-white/50 text-sm leading-relaxed line-clamp-2';
        if (user.intro) {
            intro.textContent = user.intro;
        }

        // Assemble info
        info.appendChild(topRow);
        if (user.location) info.appendChild(location);
        if (user.intro) info.appendChild(intro);
        card.appendChild(info);

        // Image section with gallery check
        const imageContainer = document.createElement('div');
        imageContainer.className = 'relative w-full pb-[100%] bg-white/5';
        
        // Try to get gallery image first
        const galleryImage = await AuthService.getFirstImage(user.id);
        
        if (galleryImage) {
            const img = document.createElement('img');
            img.className = 'absolute inset-0 w-full h-full object-cover';
            img.src = galleryImage.ImageURL;
            // Simplified transformations focusing on face detection and quality
            img.src = img.src.replace('/upload/', '/upload/c_fill,g_face,q_auto/');
            img.alt = `${user.name}'s gallery picture`;
            imageContainer.appendChild(img);
        } else if (user.pic) {
            // Fallback to profile pic
            const img = document.createElement('img');
            img.className = 'absolute inset-0 w-full h-full object-cover';
            img.src = user.pic;
            img.alt = `${user.name}'s profile picture`;
            imageContainer.appendChild(img);
        } else {
            // No image placeholder
            const placeholder = document.createElement('div');
            placeholder.className = `
                absolute inset-0
                flex flex-col items-center justify-center gap-3
                bg-gradient-to-br from-blue-600/20 via-purple-600/20 to-pink-600/20
                backdrop-blur-sm
                text-white/30
            `;
            
            const letter = document.createElement('span');
            letter.className = 'text-6xl font-light bg-gradient-to-r from-blue-400 to-purple-400 text-transparent bg-clip-text';
            letter.textContent = user.name.charAt(0).toUpperCase();
            
            const noPhotoText = document.createElement('span');
            noPhotoText.className = 'text-sm text-white/50';
            noPhotoText.textContent = "User hasn't uploaded a picture yet";
            
            placeholder.appendChild(letter);
            placeholder.appendChild(noPhotoText);
            imageContainer.appendChild(placeholder);
        }
        
        card.appendChild(imageContainer);

        return card;
    }

    public setWelcomeMessage(message: string) {
        // Update welcome section if it exists
        const welcomeSection = this.element.querySelector('.text-white/90.text-lg');
        if (welcomeSection) {
            welcomeSection.textContent = message;
        }
    }

    mount(parent: HTMLElement) {
        parent.appendChild(this.element);
    }

    unmount() {
        this.element.remove();
    }
}