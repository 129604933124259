import { ModalForm } from '../../pieces/reusable/modal-form';
import { NanoContent } from '../../pieces/platochat/nano-content';
import { NavColumn } from '../../pieces/general/nav-column';
import { ContentColumn } from '../../pieces/general/content-column';
import { DiscoveryColumn } from '../../pieces/general/discovery-column';
import { YourBig5 } from '../../pieces/general/your-big-5';
import { ContentTitle } from '../../pieces/reusable/content-title';
import { MobileNavButtons } from '../../pieces/reusable/mobile-nav-buttons';

export class NanosAppletScreen {
    private container: HTMLElement | null = null;
    private modalForm: ModalForm;
    private yourBig5: YourBig5;
    private mobileNavButtons: MobileNavButtons;

    constructor() {
        this.modalForm = new ModalForm();
        this.yourBig5 = new YourBig5(() => {
            // Empty for now since we're in nanos view
        });
        this.mobileNavButtons = new MobileNavButtons(this.modalForm, this.yourBig5);
    }

    public async mount(container: HTMLElement) {
        // Set chosen applet
        localStorage.setItem('chosenApplet', 'nanos');

        this.container = container;
        this.container.innerHTML = '';
        this.container.className = 'min-h-screen';

        // Create layout
        const columnLayout = document.createElement('div');
        columnLayout.className = 'flex min-h-screen overflow-x-hidden';

        // Content wrapper
        const contentWrapper = document.createElement('div');
        contentWrapper.className = 'flex lg:flex-row flex-col flex-1 overflow-hidden';

        // Mount columns
        const navColumn = new NavColumn();
        const contentColumn = new ContentColumn();
        const discoveryColumn = new DiscoveryColumn(this.modalForm, this.yourBig5);

        // Only mount nav column on desktop
        const navElement = navColumn.getElement();
        navElement.className = 'hidden sm:block ' + navElement.className;
        navColumn.mount(columnLayout);

        contentColumn.mount(contentWrapper);
        
        // Hide discovery column on mobile
        const discoveryElement = discoveryColumn.getElement();
        discoveryElement.className = 'hidden sm:block discovery-column ' + discoveryElement.className;
        discoveryColumn.mount(contentWrapper);

        // Load Nanos content
        await this.loadNanosContent(contentColumn.getContainer());

        columnLayout.appendChild(contentWrapper);
        
        // Mount modal form and layout
        this.modalForm.mount(container);
        container.appendChild(columnLayout);

        // Add mobile nav buttons
        this.mobileNavButtons.mount(container);
    }

    private async loadNanosContent(container: HTMLElement) {
        container.innerHTML = '';
        
        // Create main container with padding
        const mainContent = document.createElement('div');
        mainContent.className = 'max-w-3xl mx-auto px-4 py-6 space-y-6 pb-24';
        
        // Add content title
        const contentTitle = new ContentTitle('PlatoChat Nanos', 'platochat');
        contentTitle.mount(mainContent);
        
        // Create and mount nanos content
        const nanoContent = new NanoContent();
        nanoContent.mount(mainContent);
        
        container.appendChild(mainContent);
    }

    public unmount(): void {
        if (this.container) {
            this.container.innerHTML = '';
        }
        
        this.container = null;
        this.modalForm.unmount();
        this.yourBig5?.unmount();
        this.mobileNavButtons.unmount();
    }
}