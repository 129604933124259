import { ModalForm } from "./modal-form";
import { AuthService } from "../../actions/auth/api-services";

class EasyReportDialog {
    private modal: ModalForm;
    private readonly appSections = [
        'Authentication', 'Analytix', 'PlatoChat', 'Kommin', 'Design', 
        'Notifications', 'Emailing Services', 'Location Services', 
        'Profile Management', 'Search Functionality', 'Settings',
        'User Interactions', 'Content Display', 'Media Handling',
        'Performance', 'Security', 'Data Synchronization',
        'Offline Mode', 'Accessibility Features', 'Language/Localization',
        'Third-party Integrations', 'Payments/Transactions', 
        'Onboarding Process', 'Tutorial/Help', 'Other', 'Unsure'
    ];

    private readonly issueTypes = [
        'Not Functioning', 'Not Navigating', 'Permanently Loading', 'Frozen App',
        'Retrieving Incorrect Data', 'Overflow on the X or Y Axis', 
        'Crash on Specific Action', 'Unexpected Logout', 'Data Loss',
        'Slow Performance', 'High Battery Drain', 'Excessive Data Usage',
        'Inconsistent Behavior', 'UI Elements Misaligned', 'Unresponsive Buttons',
        'Incorrect Error Messages', 'Feature Not Working as Expected',
        'Compatibility Issues', 'Login/Authentication Failure',
        'Notification Issues', 'Search Not Yielding Results',
        'Sync Problems', 'Accessibility Feature Not Working',
        'Language/Translation Errors', 'Security Concern',
        'Payment Processing Issue', 'Other'
    ];

    constructor() {
        this.modal = new ModalForm();
        this.modal.mount(document.body);
    }

    public show() {
        const container = document.createElement('div');
        container.className = 'space-y-4 w-full max-w-md mx-auto';
        container.innerHTML = `
            <div class="text-center mb-4">
                <h2 class="text-xl font-semibold text-white">EasyReport an Issue</h2>
                <p class="text-sm text-white/70 mt-1">
                    Help us improve PLATO5 by reporting any issues you encounter during beta testing.
                </p>
            </div>
            
            <div class="space-y-3">
                <div class="space-y-1">
                    <label class="text-white/90 text-xs">Affected Applet</label>
                    <select class="w-full bg-white/5 border border-white/10 rounded-lg p-2 text-sm text-white">
                        ${this.appSections.map(section => 
                            `<option value="${section}">${section}</option>`
                        ).join('')}
                    </select>
                </div>

                <div class="space-y-1">
                    <label class="text-white/90 text-xs">Issue Type</label>
                    <select class="w-full bg-white/5 border border-white/10 rounded-lg p-2 text-sm text-white">
                        ${this.issueTypes.map(type => 
                            `<option value="${type}">${type}</option>`
                        ).join('')}
                    </select>
                </div>

                <div class="space-y-1">
                    <label class="text-white/90 text-xs">Description</label>
                    <textarea 
                        class="w-full bg-white/5 border border-white/10 rounded-lg p-2 text-sm text-white min-h-[100px] resize-none"
                        placeholder="Please describe the issue..."
                    ></textarea>
                </div>
            </div>

            <div class="flex justify-end gap-3 mt-6">
                <button class="cancel-btn px-4 py-2 text-sm text-white/50 hover:text-white/80 transition-colors">
                    Cancel
                </button>
                <button class="submit-btn px-4 py-2 text-sm bg-white/10 hover:bg-white/20 text-white/90 rounded-lg transition-colors">
                    Submit Report
                </button>
            </div>
        `;

        const [appletSelect, issueSelect, descriptionTextarea] = container.querySelectorAll('select, textarea');
        const cancelButton = container.querySelector('.cancel-btn');
        const submitButton = container.querySelector('.submit-btn');

        cancelButton?.addEventListener('click', () => {
            this.modal.close();
        });
        
        submitButton?.addEventListener('click', async () => {
            const userId = localStorage.getItem('userId');
            if (!userId) return;

            const success = await AuthService.submitEasyReport(
                parseInt(userId),
                (appletSelect as HTMLSelectElement).value,
                (issueSelect as HTMLSelectElement).value,
                (descriptionTextarea as HTMLTextAreaElement).value
            );

            if (success) {
                this.modal.close();
            }
        });

        this.modal.append(container);
        this.modal.open();
    }
}

export class ContentTitle {
    private element: HTMLElement;
    private iconElement: HTMLElement;
    private container: HTMLElement;
    private descriptionElement: HTMLElement;
    private reportButton?: HTMLElement;

    constructor(text: string, type: 'kommin' | 'profile' | 'nearby' | 'platochat' | 'settings' | 'ai' | 'home' = 'kommin') {
        this.container = document.createElement('div');
        this.container.className = 'flex flex-col gap-2 mb-6';

        const titleRow = document.createElement('div');
        titleRow.className = 'flex items-center justify-between gap-3';

        const leftContainer = document.createElement('div');
        leftContainer.className = 'flex items-center gap-3';

        this.iconElement = document.createElement('div');
        this.iconElement.className = 'w-10 h-10 flex items-center justify-center rounded-full text-white backdrop-blur-md bg-opacity-30 shadow-lg';
        
        switch (type) {
            case 'kommin':
                this.iconElement.className += ' bg-orange-500/30';
                this.iconElement.innerHTML = '<span class="text-base font-bold">K</span>';
                break;
            case 'profile':
                this.iconElement.className += ' bg-yellow-300/30';
                this.iconElement.innerHTML = '<svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"></path></svg>';
                break;
            case 'nearby':
                this.iconElement.className += ' bg-purple-500/30';
                this.iconElement.innerHTML = '<svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"></path></svg>';
                break;
            case 'platochat':
                this.iconElement.className += ' bg-blue-400/30';
                this.iconElement.innerHTML = '<svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"></path></svg>';
                break;
            case 'settings':
                this.iconElement.className += ' bg-slate-400/30';
                this.iconElement.innerHTML = '<svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"></path><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path></svg>';
                break;
            case 'ai':
                this.iconElement.className += ' bg-emerald-500/30';
                this.iconElement.innerHTML = '<span class="text-base font-mono font-bold text-emerald-400">Ai</span>';
                break;
            case 'home':
                this.iconElement.className += ' bg-pink-500/10 border border-pink-500/20 shadow-[0_0_10px_rgba(236,72,153,0.1)]';
                this.iconElement.innerHTML = `
                    <svg class="w-5 h-5 text-pink-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path>
                    </svg>
                `;
                break;
        }

        this.element = document.createElement('h1');
        
        if (type === 'home') {
            this.element.textContent = 'PLATO5 Beta';
            this.element.className = 'text-2xl font-bold text-pink-400';
        } else {
            this.element.textContent = text;
            this.element.className = 'text-2xl font-bold bg-clip-text text-transparent';
            
            switch (type) {
                case 'kommin':
                    this.element.className += ' bg-gradient-to-r from-orange-500 to-amber-400';
                    break;
                case 'profile':
                    this.element.className += ' bg-gradient-to-r from-yellow-300 to-amber-500';
                    break;
                case 'nearby':
                    this.element.className += ' bg-gradient-to-r from-purple-500 to-red-400';
                    break;
                case 'platochat':
                    this.element.className += ' bg-gradient-to-r from-blue-400 to-indigo-600';
                    break;
                case 'settings':
                    this.element.className += ' bg-gradient-to-r from-slate-400 to-zinc-500';
                    break;
                case 'ai':
                    this.element.className += ' bg-gradient-to-r from-emerald-400 to-teal-500';
                    break;
            }
        }

        leftContainer.appendChild(this.iconElement);
        leftContainer.appendChild(this.element);
        titleRow.appendChild(leftContainer);

        // Only add report section if user is logged in
        const userId = localStorage.getItem('userId');
        if (userId) {
            // Create report section with icon and text
            const reportSection = document.createElement('div');
            reportSection.className = 'flex items-center gap-2';

            const problemText = document.createElement('span');
            problemText.className = 'text-xs text-white/50';
            problemText.textContent = 'Problem?';

            this.reportButton = document.createElement('button');
            this.reportButton.className = 'px-3 py-1.5 text-xs font-medium text-white/50 hover:text-white/80 transition-colors rounded-full border border-white/10 hover:border-white/20 bg-white/5';
            this.reportButton.innerHTML = `<i class="fa-solid fa-bug"></i>`;

            this.reportButton.addEventListener('click', () => {
                const dialog = new EasyReportDialog();
                dialog.show();
            });

            reportSection.appendChild(problemText);
            reportSection.appendChild(this.reportButton);
            titleRow.appendChild(reportSection);
        }

        // Add description
        this.descriptionElement = document.createElement('p');
        this.descriptionElement.className = 'text-sm text-white/50 ml-[52px]';
        
        switch (type) {
            case 'kommin':
                this.descriptionElement.textContent = 'Join discussions about your favorite topics';
                break;
            case 'profile':
                this.descriptionElement.textContent = 'Manage your profile and personality analytix';
                break;
            case 'nearby':
                this.descriptionElement.textContent = 'Find local events to join (coming soon)';
                break;
            case 'platochat':
                this.descriptionElement.textContent = 'Chat with your connections';
                break;
            case 'settings':
                this.descriptionElement.textContent = 'Customize your PLATO5 preferences';
                break;
            case 'ai':
                this.descriptionElement.textContent = 'Stuck overthinking a response? Ask our AI conversation assistants for help';
                break;
            case 'home':
                this.descriptionElement.textContent = 'Use our matching system to find friends and start new conversations';
                break;
        }

        this.container.appendChild(titleRow);
        this.container.appendChild(this.descriptionElement);
    }

    mount(parent: HTMLElement) {
        parent.appendChild(this.container);
    }

    public getContainer(): HTMLElement {
        return this.container;
    }
} 