interface MetaData {
  title: string;
  description: string;
  image: string;
  keywords: string;
  author: string;
  website: string;
}

interface RouteMetadata {
  [key: string]: MetaData;
}

const defaultMetadata: MetaData = {
  title: "PLATO5  Meet Someone New",
  description: "PLATO5: create platonic relationships based on the Big 5 Personality Traits. PLATO5 is a social engine that matches you with people in your area with similar personality profiles and interests using our proprietary algorithm called PIMS (Personality Interest Match System).",
  image: "/favicon.ico",
  keywords: "PLATO5, platonic relationships, people near me, people, nearby, big 5 personality traits, social engine, PIMS, personality match system",
  author: "Nick Goldstein",
  website: "https://plato5.us"
};

const routeMetadata: RouteMetadata = {
  '/': defaultMetadata,
  
  '/features': {
    ...defaultMetadata,
    title: "PLATO5 Features: A Social Engine for Meaningful Connections",
    description: "Discover PLATO5's innovative features: personality matching, interest-based discussions, Conversation Assistant AIs, and safe meetup tools for creating meaningful platonic relationships.",
    keywords: "PLATO5 features, personality matching, platonic relationships, social networking, meetup tools"
  },

  '/about/how-social-engines-can-solve-the-loneliness-epidemic': {
    ...defaultMetadata,
    title: "How Social Engines Can Solve the Loneliness Epidemic | PLATO5 The Social Engine",
    description: "Learn how PLATO5's social engine technology is addressing the global loneliness epidemic through meaningful connections and personality-based matching.",
    keywords: "loneliness epidemic, social engines, PLATO5, meaningful connections, personality matching"
  },

  '/about/gamified-conversations-and-nano-blogging': {
    ...defaultMetadata,
    title: "Gamified Conversations & Nano Blogging | PLATO5 The Social Engine",
    description: "Explore how PLATO5 uses gamification and nano blogging to create engaging social interactions and meaningful conversations.",
    keywords: "gamified conversations, nano blogging, PLATO5, social engagement, online interactions"
  },

  '/about/explore-the-plato5-suite-kommin-platochat-and-more': {
    ...defaultMetadata,
    title: "PLATO5 Suite: Kommin, PlatoChat & More | PLATO5 The Social Engine",
    description: "Discover the PLATO5 suite of tools including Kommin for discussions and PlatoChat for direct messaging, designed for meaningful connections.",
    keywords: "PLATO5 suite, Kommin, PlatoChat, social tools, messaging platform"
  },

  '/about/safety-suggestions-for-online-meetups': {
    ...defaultMetadata,
    title: "Safety Suggestions for Online Meetups | PLATO5 The Social Engine",
    description: "Essential safety tips and guidelines for organizing and attending online meetups through PLATO5's social platform.",
    keywords: "online meetup safety, PLATO5 safety, meetup guidelines, online safety tips"
  },

  '/platochat/nanos': {
    ...defaultMetadata,
    title: "PLATO5 Nano-Blogs",
    description: "Share and discover quick updates with PLATO5 Nanos - our nano-blogging platform for meaningful social interactions.",
    keywords: "PLATO5 nanos, nano-blogs, social interactions, quick updates"
  },
  
  '/kommin': {
    ...defaultMetadata,
    title: "PLATO5 Kommin Discussion Board",
    description: "Join meaningful discussions in PLATO5 Kommin - our interest-tracking community platform for deeper conversations.",
    keywords: "PLATO5 kommin, discussion board, interest-tracking, deeper conversations"
  },
  
  '/nearby': {
    ...defaultMetadata,
    title: "PLATO5 Nearby",
    description: "PLATO5's event finder",
    keywords: "PLATO5 nearby, event finder, social interactions"
  },
  
  '/your-profile': {
    ...defaultMetadata,
    title: "Your PLATO5 Profile",
    description: "Manage your PLATO5 profile and preferences.",
    keywords: "PLATO5 profile, profile management, preferences"
  },
  
  '/ai': {
    ...defaultMetadata,
    title: "PLATO5 AI Assistants: Zenith and Steward",
    description: "Get help with conversations and connections from our AI assistants.",
    keywords: "PLATO5 AI assistants, Zenith, Steward, conversation assistance"
  },
  
  '/platochat/icebreakers': {
    ...defaultMetadata,
    title: "PLATO5 Icebreakers",
    description: "Find perfect conversation starters with our icebreaker suggestions.",
    keywords: "PLATO5 icebreakers, conversation starters, icebreaker suggestions"
  },
  
  '/settings': {
    ...defaultMetadata,
    title: "PLATO5 Configure Settings",
    description: "Manage your PLATO5 account settings and preferences.",
    keywords: "PLATO5 settings, account settings, preferences"
  }
};

export function getMetadata(path: string): MetaData {
  return routeMetadata[path] || defaultMetadata;
}

export function updatePageMetadata(path: string): void {
  const metadata = getMetadata(path);
  
  document.title = metadata.title;
  
  // Update favicon links
  const faviconSizes = ['16x16', '32x32', '180x180'];
  const faviconRels = ['icon', 'icon', 'apple-touch-icon'];
  
  faviconSizes.forEach((size, index) => {
    let link = document.querySelector(`link[rel="${faviconRels[index]}"][sizes="${size}"]`);
    if (!link) {
      link = document.createElement('link');
      link.setAttribute('rel', faviconRels[index]);
      link.setAttribute('sizes', size);
      link.setAttribute('type', 'image/png');
      document.head.appendChild(link);
    }
    link.setAttribute('href', metadata.image);
  });
  
  // Update meta tags
  const metaTags = {
    'description': metadata.description,
    'keywords': metadata.keywords,
    'author': metadata.author,
    'theme-color': '#000000',
    
    'og:type': 'website',
    'og:title': metadata.title,
    'og:description': metadata.description,
    'og:image': `${metadata.website}${metadata.image}`,
    'og:url': `${metadata.website}${path}`,
    
    'twitter:card': 'summary_large_image',
    'twitter:title': metadata.title,
    'twitter:description': metadata.description,
    'twitter:image': `${metadata.website}${metadata.image}`
  };

  Object.entries(metaTags).forEach(([name, content]) => {
    let meta = document.querySelector(`meta[name="${name}"]`) || 
               document.querySelector(`meta[property="${name}"]`);
    
    if (!meta) {
      meta = document.createElement('meta');
      if (name.startsWith('og:')) {
        meta.setAttribute('property', name);
      } else {
        meta.setAttribute('name', name);
      }
      document.head.appendChild(meta);
    }
    
    meta.setAttribute('content', content);
  });

  // Update canonical link
  let canonical = document.querySelector('link[rel="canonical"]');
  if (!canonical) {
    canonical = document.createElement('link');
    canonical.setAttribute('rel', 'canonical');
    document.head.appendChild(canonical);
  }
  canonical.setAttribute('href', `${metadata.website}${path}`);
}

// Add interface for discussion metadata
export interface DiscussionMetadata {
  title: string;
  description: string;
  author: string;
  created: string;
}

// Add function to handle dynamic discussion metadata
export async function updateDiscussionMetadata(hashedId: string, data: DiscussionMetadata): Promise<void> {
  const metadata = {
    ...defaultMetadata,
    title: `${data.title} | PLATO5 Kommin Discussion`,
    description: `Join the discussion about ${data.title} by ${data.author} on PLATO5 Kommin. ${data.description}`.slice(0, 160),
    keywords: `PLATO5, Kommin, discussion, ${data.title}, community discussion`,
  };

  const metaTags = {
    'description': metadata.description,
    'keywords': metadata.keywords,
    'author': metadata.author,
    'theme-color': '#000000',
    
    'og:type': 'article',  // Changed to article for discussions
    'og:title': metadata.title,
    'og:description': metadata.description,
    'og:image': `${metadata.website}${metadata.image}`,
    'og:url': `${metadata.website}/kommin/discussion/${hashedId}`,
    'article:published_time': data.created,
    'article:author': data.author,
    
    'twitter:card': 'summary_large_image',
    'twitter:title': metadata.title,
    'twitter:description': metadata.description,
    'twitter:image': `${metadata.website}${metadata.image}`
  };

  // Update meta tags
  Object.entries(metaTags).forEach(([name, content]) => {
    let meta = document.querySelector(`meta[name="${name}"]`) || 
               document.querySelector(`meta[property="${name}"]`);
    
    if (!meta) {
      meta = document.createElement('meta');
      if (name.startsWith('og:') || name.startsWith('article:')) {
        meta.setAttribute('property', name);
      } else {
        meta.setAttribute('name', name);
      }
      document.head.appendChild(meta);
    }
    
    meta.setAttribute('content', content);
  });

  // Update canonical link
  let canonical = document.querySelector('link[rel="canonical"]');
  if (!canonical) {
    canonical = document.createElement('link');
    canonical.setAttribute('rel', 'canonical');
    document.head.appendChild(canonical);
  }
  canonical.setAttribute('href', `${metadata.website}/kommin/discussion/${hashedId}`);
}

export default routeMetadata;