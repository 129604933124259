import { DownloadContent } from '../reusable/download-content';
import { ArticleContent } from '../tutorial/article-content';
import { AuthContent } from '../auth/auth-content';
import { navigate } from '../../app-router';
export class DiscoveryColumn {
    private container: HTMLElement;
    private parentElement: HTMLElement | null = null;
    private element: HTMLElement;
    private resizeObserver: ResizeObserver;

    constructor(private modalForm: any, private yourBig5: any) {
        this.container = document.createElement('div');
        this.container.className = `
            lg:w-1/3 flex flex-col 
            bg-black/15 backdrop-blur-xl 
            border border-white/10 rounded-2xl 
            mt-2 mr-2
            overflow-y-auto no-scrollbar
        `;
        
        this.initializeLayout();
        this.element = this.container;
        
        // Initialize resize observer
        this.resizeObserver = new ResizeObserver(this.handleResize.bind(this));
    }

    private handleResize(): void {
        if (!this.parentElement) return;

        if (window.innerWidth >= 1024) {  // lg breakpoint
            if (!this.container.parentElement) {
                this.initializeLayout();
                this.parentElement.appendChild(this.container);
            }
        } else {
            // Switch to mobile view
            this.container.remove();
        }
    }

    private initializeLayout(): void {
        // Clear existing content
        this.container.innerHTML = '';
        
        // Content section
        const contentSection = document.createElement('div');
        contentSection.className = 'p-4 flex flex-col flex-1';
        
        // Mount Your Big 5 into content section
        this.yourBig5.mount(contentSection);

        // Add iOS Coming Soon notice
        const iosNotice = document.createElement('div');
        iosNotice.className = `
            w-full mx-auto mt-4
            bg-gradient-to-r from-blue-500/10 to-purple-500/10
            border border-white/10
            rounded-xl p-4 mb-4
            flex flex-col gap-3
        `;
        iosNotice.innerHTML = `
            <div class="flex items-center gap-3">
                <i class="fab fa-apple text-2xl text-white/70"></i>
                <div>
                    <div class="text-sm font-medium text-white/90">iOS App Coming Soon</div>
                    <div class="text-xs text-white/70">Get ready for a native mobile experience</div>
                </div>
            </div>
            <div class="flex flex-col gap-2">
                <a href="https://twitter.com/intent/follow?screen_name=GoldsteinNichol" 
                   target="_blank" 
                   rel="noopener noreferrer" 
                   class="text-xs text-purple-300/70 hover:text-purple-300/90 transition-colors flex items-center justify-center gap-1.5">
                    <i class="fab fa-x-twitter"></i>
                    <span>Follow for Updates</span>
                </a>
            </div>
        `;
        contentSection.appendChild(iosNotice);

        // Features Button
        const featuresButton = document.createElement('button');
        featuresButton.className = 'features-btn mt-6 mb-6';
        featuresButton.textContent = 'FEATURES';
        featuresButton.onclick = () => {
            navigate('/features');
        };
        contentSection.appendChild(featuresButton);

        // Affiliate Button
        const affiliateButton = document.createElement('button');
        affiliateButton.className = `
            mt-1 py-3 px-4
            text-white/80 hover:text-white
            text-sm font-medium
            flex items-center justify-center gap-2.5
            rounded-xl
            border border-purple-500/20
            bg-gradient-to-r from-purple-500/10 to-blue-500/10
            hover:from-purple-500/20 hover:to-blue-500/20
            hover:border-purple-500/30
            transition-all duration-300
            shadow-lg shadow-purple-500/5
            hover:shadow-purple-500/10
        `;
        affiliateButton.innerHTML = '<i class="fas fa-users text-purple-400/90"></i> Become an Affiliate';
        affiliateButton.onclick = () => {
            navigate('/programs/affiliates');
        };
        contentSection.appendChild(affiliateButton);

        // Engagement Driver Button
        const driverButton = document.createElement('button');
        driverButton.className = `
            mt-2 py-3 px-4
            text-white/80 hover:text-white
            text-sm font-medium
            flex items-center justify-center gap-2.5
            rounded-xl
            border border-blue-500/20
            bg-gradient-to-r from-blue-500/10 to-cyan-500/10
            hover:from-blue-500/20 hover:to-cyan-500/20
            hover:border-blue-500/30
            transition-all duration-300
            shadow-lg shadow-blue-500/5
            hover:shadow-blue-500/10
        `;
        driverButton.innerHTML = '<i class="fas fa-chart-line text-blue-400/90"></i> Become an Engagement Driver';
        driverButton.onclick = () => {
            navigate('/programs/engagement-drivers');
        };
        contentSection.appendChild(driverButton);

        // Add logout button if user is logged in
        const userId = localStorage.getItem('userId');
        if (userId) {
            const logoutButton = document.createElement('button');
            logoutButton.className = `
                w-full mx-auto
                mt-8 py-3 px-4
                flex items-center justify-center gap-2
                text-white/50 hover:text-white/70
                transition-colors
                text-sm
            `;
            logoutButton.innerHTML = `
                <i class="fas fa-sign-out-alt"></i>
                <span>Sign Out</span>
            `;
            logoutButton.addEventListener('click', () => {
                localStorage.clear();
                window.location.href = '/';
            });
            contentSection.appendChild(logoutButton);
        }
        
        this.container.appendChild(contentSection);
    }

    public mount(parent: HTMLElement): void {
        this.parentElement = parent;
        parent.appendChild(this.container);
        
        // Start observing viewport changes
        this.resizeObserver.observe(document.body);
    }

    public unmount(): void {
        this.resizeObserver.disconnect();
        this.container.remove();
        this.parentElement = null;
    }

    public getElement(): HTMLElement {
        return this.element;
    }
}