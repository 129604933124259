import { navigate } from '../app-router';
import { ContentTitle } from '../pieces/reusable/content-title';
import { TopBar } from '../pieces/reusable/top-bar';
import { ModalForm } from '../pieces/reusable/modal-form';

export class AffiliateScreen {
    private container: HTMLElement | null = null;
    private modalForm: ModalForm;
    private topBar: TopBar;

    constructor() {
        this.modalForm = new ModalForm();
        this.topBar = new TopBar(this.modalForm);
    }

    public mount(container: HTMLElement) {
        this.container = container;
        this.container.innerHTML = '';
        this.container.className = 'min-h-screen';

        this.modalForm.mount(this.container);
        this.topBar.mount(this.container);

        const content = document.createElement('div');
        content.className = 'max-w-3xl mx-auto p-4 sm:p-8 mt-8';

        // Hero Section
        const hero = document.createElement('div');
        hero.className = 'text-center mb-12';
        hero.innerHTML = `
            <h1 class="text-3xl sm:text-4xl font-semibold mb-3 bg-gradient-to-r from-purple-400 to-pink-400 text-transparent bg-clip-text">
                Become a PLATO5 Affiliate
            </h1>
            <p class="text-white/60 text-base max-w-xl mx-auto">
                Join us to help create meaningful connections in your community
            </p>
        `;

        // Coming Soon Banner
        const comingSoonBanner = document.createElement('div');
        comingSoonBanner.className = 'mb-10 px-6 py-4 bg-amber-500/20 border-2 border-amber-500/30 rounded-lg text-center';
        comingSoonBanner.innerHTML = `
            <div class="flex items-center justify-center gap-2">
                <i class="fas fa-clock text-amber-400 text-xl"></i>
                <span class="text-amber-400 font-medium text-lg">Program Launching Soon</span>
            </div>
            <p class="text-amber-400/80 text-sm mt-1">We're currently finalizing the details of our affiliate program</p>
        `;

        // Benefits Section
        const benefits = document.createElement('div');
        benefits.className = 'mb-10';
        benefits.innerHTML = `
            <div class="flex flex-col gap-3">
                <div class="bg-black/20 backdrop-blur-sm border border-white/10 rounded-lg p-6 hover:bg-black/30 transition-all">
                    <div class="flex items-center gap-4">
                        <div class="w-10 h-10 rounded-lg bg-purple-500/20 flex items-center justify-center">
                            <i class="fas fa-star text-purple-400 text-lg"></i>
                        </div>
                        <div>
                            <h3 class="text-lg font-medium text-white/90 mb-1">Affiliate Benefits</h3>
                            <p class="text-white/60 text-sm">Join our exclusive network of affiliates and earn rewards for successful referrals</p>
                        </div>
                    </div>
                </div>

                <div class="bg-black/20 backdrop-blur-sm border border-white/10 rounded-lg p-6 hover:bg-black/30 transition-all">
                    <div class="flex items-center gap-4">
                        <div class="w-10 h-10 rounded-lg bg-pink-500/20 flex items-center justify-center">
                            <i class="fas fa-chart-line text-pink-400 text-lg"></i>
                        </div>
                        <div>
                            <h3 class="text-lg font-medium text-white/90 mb-1">Performance Dashboard</h3>
                            <p class="text-white/60 text-sm">Track your impact with our intuitive analytics platform</p>
                        </div>
                    </div>
                </div>
            </div>
        `;

        // Commission Structure
        const commissionSection = document.createElement('div');
        commissionSection.className = 'mb-10';
        commissionSection.innerHTML = `
            <div class="bg-black/20 backdrop-blur-sm border border-white/10 rounded-lg p-6 hover:bg-black/30 transition-all">
                <div class="flex items-center gap-4 mb-4">
                    <div class="w-10 h-10 rounded-lg bg-emerald-500/20 flex items-center justify-center">
                        <i class="fas fa-dollar-sign text-emerald-400 text-lg"></i>
                    </div>
                    <h3 class="text-lg font-medium text-white/90">Commission Structure (To Be Announced)</h3>
                </div>
                <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    <div class="bg-black/20 rounded-lg p-4">
                        <div class="text-sm font-medium text-white/90 mb-1">Standard Rate</div>
                        <p class="text-white/60 text-sm">Percentage of premium subscription payments (TBD)</p>
                    </div>
                    <div class="bg-black/20 rounded-lg p-4">
                        <div class="text-sm font-medium text-white/90 mb-1">Performance Bonuses</div>
                        <p class="text-white/60 text-sm">Additional rewards for high-performing affiliates (TBD)</p>
                    </div>
                </div>
            </div>
        `;

        // Application Form
        const form = document.createElement('form');
        form.className = 'bg-black/20 backdrop-blur-sm border border-white/10 rounded-lg p-6';
        form.innerHTML = `
            <h2 class="text-lg font-medium text-white/90 mb-6">Become an Affiliate</h2>
            
            <div class="space-y-4">
                <div>
                    <label class="block text-white/60 text-sm mb-1">Full Name</label>
                    <input type="text" 
                        class="w-full px-3 py-2 rounded-lg bg-black/40 border border-white/10 text-white/90 focus:border-purple-500/50 focus:outline-none text-sm cursor-not-allowed" 
                        required
                        disabled
                    >
                </div>
                
                <div>
                    <label class="block text-white/60 text-sm mb-1">Email Address</label>
                    <input type="email" 
                        class="w-full px-3 py-2 rounded-lg bg-black/40 border border-white/10 text-white/90 focus:border-purple-500/50 focus:outline-none text-sm cursor-not-allowed" 
                        required
                        disabled
                    >
                </div>

                <div>
                    <label class="block text-white/60 text-sm mb-1">How do you plan on promoting PLATO5?</label>
                    <textarea 
                        class="w-full px-3 py-2 rounded-lg bg-black/40 border border-white/10 text-white/90 focus:border-purple-500/50 focus:outline-none text-sm h-24 cursor-not-allowed" 
                        required
                        disabled
                    ></textarea>
                </div>

                <button type="submit" class="
                    w-full py-2 px-4
                    bg-gradient-to-r from-purple-500/50 to-pink-500/50
                    text-white/50 text-sm font-medium
                    rounded-lg
                    cursor-not-allowed
                    transition-all duration-200
                    flex items-center justify-center gap-2
                    mt-2
                " disabled>
                    <i class="fas fa-paper-plane"></i>
                    Coming Soon
                </button>
            </div>
        `;

        // Assemble the page
        content.appendChild(hero);
        content.appendChild(comingSoonBanner);
        content.appendChild(benefits);
        content.appendChild(commissionSection);
        content.appendChild(form);
        
        this.container.appendChild(content);
    }

    public unmount(): void {
        if (this.container) {
            this.container.innerHTML = '';
        }
        this.container = null;
        this.topBar.unmount();
        this.modalForm.unmount();
    }
}