export class ContentColumn {
    private container: HTMLElement;

    constructor() {
        this.container = document.createElement('div');
        this.container.className = `
            lg:w-2/3 w-full 
            px-2 sm:px-4 
            backdrop-blur-lg
            overflow-y-auto
            max-h-screen
            overflow-x-hidden
            no-scrollbar
        `;

        // Add the scrollbar hiding styles
        const style = document.createElement('style');
        style.textContent = `
            .no-scrollbar::-webkit-scrollbar {
                display: none;
            }
            .no-scrollbar {
                -ms-overflow-style: none;
                scrollbar-width: none;
            }
        `;
        document.head.appendChild(style);
    }

    public mount(parent: HTMLElement): void {
        parent.appendChild(this.container);
    }

    public getContainer(): HTMLElement {
        return this.container;
    }

    public unmount(): void {
        this.container.remove();
    }
}