import { DailyCommib } from '../platochat/daily-commib';

export class IcebreakerContent {
    private element: HTMLElement;
    private dailyCommib: DailyCommib;

    constructor() {
        this.element = document.createElement('div');
        const userId = localStorage.getItem('userId');
        this.dailyCommib = new DailyCommib(userId ? parseInt(userId) : null);
    }

    public mount(parent: HTMLElement): void {
        this.element.innerHTML = '';
        this.dailyCommib.mount(this.element);
        parent.appendChild(this.element);
    }

    public unmount(): void {
        this.element.remove();
    }
}