import { Button } from '../reusable/button';
import { AuthService } from '../../actions/auth/api-services';


export class SettingsContent {
    private element: HTMLDivElement;

    constructor() {
        this.element = document.createElement('div');
        this.element.className = 'space-y-4 p-2';
        
        // Tutorial Video Section (always show)
        const videoSection = this.createSection('Tutorial Video', 'fa-video');
        const videoContainer = document.createElement('div');
        videoContainer.className = 'relative w-full sm:w-[400px] aspect-video rounded-lg overflow-hidden shadow-md ml-0';
        videoContainer.innerHTML = `
            <iframe 
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/SpwQBoC9EqE" 
                frameborder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowfullscreen
                class="absolute top-0 left-0 w-full h-full"
            ></iframe>
        `;
        videoSection.appendChild(videoContainer);

        // Features Link Section (always show)
        const featuresSection = this.createSection('Features', 'fa-star');
        const featuresLink = document.createElement('a');
        featuresLink.href = '/features';
        featuresLink.className = 'text-purple-400 hover:text-purple-300 transition-colors duration-300 flex items-center gap-2';
        featuresLink.innerHTML = `
            Check out upcoming features here 
            <i class="fas fa-arrow-right text-sm"></i>
        `;
        featuresSection.appendChild(featuresLink);

        // Check if user is logged in
        const userId = localStorage.getItem('userId');
        if (userId) {
            // Toggles Section (only for logged in users)
            const togglesSection = this.createSection('Preferences', 'fa-sliders-h');
            const gamificationToggle = this.createToggle('Enable Gamification', 'gamification');
            const newsletterToggle = this.createToggle('Subscribe to Newsletter', 'newsletter');
            togglesSection.append(gamificationToggle, newsletterToggle);

            // Account Section with logout (only for logged in users)
            const accountSection = this.createSection('Profile', 'fa-user');
            const logoutButton = new Button('Logout');
            logoutButton.getElement().className = `
                w-full px-4 py-2.5 text-sm font-medium bg-gradient-to-br from-red-500 to-pink-600 hover:from-red-600 hover:to-pink-700 text-white rounded-lg transition-all duration-300 shadow-md hover:shadow-red-500/25 hover:scale-[1.01] active:scale-[0.99] transform backdrop-blur-sm border border-white/10
            `;
            logoutButton.getElement().innerHTML = `
                <i class="fas fa-sign-out-alt mr-2"></i>
                Logout
            `;
            logoutButton.onClick(async () => {
                await AuthService.logout();
                localStorage.removeItem('userId');
                window.location.reload();
            });
            accountSection.appendChild(logoutButton.getElement());

            this.element.append(
                videoSection,
                togglesSection,
                featuresSection,
                accountSection
            );
            
            // Initialize toggle states
            this.initializeToggles();
        } else {
            // Only append the always-visible sections for logged out users
            this.element.append(
                videoSection,
                featuresSection
            );
        }
    }

    private createSection(title: string, iconClass: string): HTMLDivElement {
        const section = document.createElement('div');
        section.className = 'space-y-3';
        
        const heading = document.createElement('h3');
        heading.className = 'text-base font-medium text-white/90 flex items-center gap-2';
        heading.innerHTML = `
            <i class="fas ${iconClass}"></i>
            ${title}
        `;
        
        section.appendChild(heading);
        return section;
    }

    private async initializeToggles(): Promise<void> {
        const userId = localStorage.getItem('userId');
        if (!userId) return;

        const userData = await AuthService.getUserById(parseInt(userId));
        if (!userData) return;

        const gamificationToggle = this.element.querySelector('[data-preference="gamification"]');
        const newsletterToggle = this.element.querySelector('[data-preference="newsletter"]');
        
        if (gamificationToggle) {
            this.updateToggleState(gamificationToggle as HTMLElement, userData.gamification || false);
        }
        if (newsletterToggle) {
            this.updateToggleState(newsletterToggle as HTMLElement, userData.newsletter || false);
        }
    }

    private createToggle(label: string, preference: string): HTMLDivElement {
        const container = document.createElement('div');
        container.className = 'flex items-center justify-between py-1.5 px-2 hover:bg-white/5 rounded-lg cursor-pointer transition-colors duration-300';
        container.dataset.preference = preference;

        const labelElement = document.createElement('span');
        labelElement.className = 'text-white/70';
        labelElement.textContent = label;

        const toggle = document.createElement('div');
        toggle.className = `
            relative 
            w-10 
            h-5 
            rounded-full 
            bg-purple-600/20 
            transition-colors 
            duration-300
        `;

        const toggleCircle = document.createElement('span');
        toggleCircle.className = `
            absolute 
            left-1 
            top-1 
            w-3 
            h-3 
            rounded-full 
            bg-white 
            transition-transform 
            duration-300
        `;

        toggle.appendChild(toggleCircle);
        container.append(labelElement, toggle);

        container.addEventListener('click', async () => {
            const userId = localStorage.getItem('userId');
            if (!userId) return;

            const isActive = toggle.classList.contains('bg-purple-600');
            const success = await AuthService.updateUser(parseInt(userId), {
                [preference]: !isActive
            });

            if (success) {
                this.updateToggleState(container, !isActive);
                if (preference === 'gamification') {
                    window.location.reload();
                }
            }
        });

        return container;
    }

    private updateToggleState(container: HTMLElement, isActive: boolean): void {
        const toggle = container.querySelector('div');
        const toggleCircle = container.querySelector('span:last-child') as HTMLElement;
        
        if (toggle && toggleCircle) {
            if (isActive) {
                toggle.className = 'relative w-10 h-5 rounded-full bg-purple-600 transition-colors duration-300';
                toggleCircle.style.transform = 'translateX(20px)';
            } else {
                toggle.className = 'relative w-10 h-5 rounded-full bg-white/10 transition-colors duration-300';
                toggleCircle.style.transform = 'translateX(0)';
            }
        }
    }

    public mount(parent: HTMLElement): void {
        parent.appendChild(this.element);
    }

    public unmount(): void {
        this.element.remove();
    }

    public getElement(): HTMLElement {
        return this.element;
    }
}