import { UINotifications } from '../../utilities/errors';
import { AuthService } from '../auth/api-services';
import { NanoResponse, CommibResponse, ChatResponse } from './models';
import { PanelService } from '../your-big-5/api-services';
import { CreatePanelRequest } from '../your-big-5/models';
import { Hash } from '../../utilities/hash';
const API_URL = import.meta.env.VITE_PLATOCHAT_API_URL
const API_KEY = import.meta.env.VITE_API_KEY

export class PlatoChatService {
    private static getHeaders(): Record<string, string> {
        const baseHeaders = {
            'Content-Type': 'application/json',
            'X-API-Key': API_KEY
        };

        const sessionData = AuthService.getSessionData();
        if (sessionData) {
            return {
                ...baseHeaders,
                'X-Session-ID': sessionData.session_id,
                'X-Public-Key': sessionData.public_key,
                'X-User-ID': sessionData.userId?.toString() || ''
            };
        }

        return baseHeaders;
    }

    static async getWalletBalance(userId: number): Promise<number | null> {
        try {
            const response = await fetch(`${API_URL}/get-wallet-by-user-id?user_id=${userId}`, {
                method: 'GET',
                headers: this.getHeaders()
            });

            const data = await response.json();
            if (!response.ok) throw new Error(data.error || 'Failed to get wallet balance');

            return data.wallet.points;
        } catch (error) {
            console.error('Failed to get wallet balance:', error);
            return null;
        }
    }

    static async createWallet(userId: number): Promise<boolean> {
        try {
            const response = await fetch(`${API_URL}/create-wallet`, {
                method: 'POST',
                headers: this.getHeaders(),
                body: JSON.stringify({ user_id: userId })
            });

            if (!response.ok) throw new Error('Failed to create wallet');
            return true;
        } catch (error) {
            console.error('Failed to create wallet:', error);
            return false;
        }
    }

    static async addToBalance(userId: number, amount: number): Promise<boolean> {
        try {
            const response = await fetch(`${API_URL}/add-to-balance`, {
                method: 'POST',
                headers: this.getHeaders(),
                body: JSON.stringify({ user_id: userId, amount })
            });

            if (!response.ok) throw new Error('Failed to add to balance');
            return true;
        } catch (error) {
            console.error('Failed to add to balance:', error);
            return false;
        }
    }

    static async deductFromBalance(userId: number, amount: number): Promise<boolean> {
        try {
            const response = await fetch(`${API_URL}/deduct-from-balance`, {
                method: 'POST',
                headers: this.getHeaders(),
                body: JSON.stringify({ user_id: userId, amount })
            });

            if (!response.ok) throw new Error('Failed to deduct from balance');
            return true;
        } catch (error) {
            console.error('Failed to deduct from balance:', error);
            return false;
        }
    }

    public static async checkGamificationAndPoints(userId: number, requiredPoints: number): Promise<boolean> {
        try {
            const user = await AuthService.getUserById(userId);
            if (!user) throw new Error('User not found');

            // If gamification is disabled, allow the action
            if (!user.gamification) return true;

            // Check points balance
            const balance = await this.getWalletBalance(userId);
            if (balance === null || balance < requiredPoints) {
                UINotifications.shipErrorToUI(
                    `Not enough points (${requiredPoints} required). You can disable gamification in settings.`
                );
                return false;
            }

            return true;
        } catch (error) {
            console.error('Failed to check gamification status:', error);
            return false;
        }
    }

    private static mapToPanelRequest(panel: any): CreatePanelRequest {
        // Process route if it contains hashedId
        let route = panel.route;
        const match = route.match(/hashedId\((\d+)\)/);
        if (match) {
            const id = parseInt(match[1]);
            const hashedId = Hash.encodeId(id);
            route = route.replace(/hashedId\(\d+\)/, hashedId);
        }

        return {
            userId: panel.user_id,
            title: panel.title,
            content: panel.content,
            modal: panel.modal,
            route: route,  // Use the processed route
            points: panel.points,
            applet: panel.applet
        };
    }

    static async createNano(userId1: number, userId2: number, userId3: number, myUserId: number): Promise<number | null> {
        try {
            const canProceed = await this.checkGamificationAndPoints(myUserId, 2000);
            if (!canProceed) return null;

            const response = await fetch(`${API_URL}/create-nano`, {
                method: 'POST',
                headers: this.getHeaders(),
                body: JSON.stringify({
                    user1_id: userId1,
                    user2_id: userId2,
                    user3_id: userId3,
                    user4_id: myUserId
                })
            });

            const data = await response.json();
            if (!response.ok) throw new Error('Failed to create nano');
            
            if (data.panels) {
                await Promise.all(data.panels.map((panel: any) => 
                    PanelService.createPanel(this.mapToPanelRequest(panel))
                ));
            }
            
            const user = await AuthService.getUserById(myUserId);
            if (user?.gamification) {
                await this.deductFromBalance(myUserId, 2000);
            }
            
            return data.data.ID;
        } catch (error) {
            console.error('Failed to create nano:', error);
            return null;
        }
    }

    static async getNanosByLastChat(offset: number, userId: number): Promise<NanoResponse[] | null> {
        try {
            const response = await fetch(`${API_URL}/get-nanos-by-last-chat?userId=${userId}&offset=${offset}`, {
                method: 'GET',
                headers: this.getHeaders()
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to fetch nanos');
            }

            const data = await response.json();
            return data.data.map((nano: any) => ({
                ID: nano.ID,
                user1_id: nano.user1_id,
                user2_id: nano.user2_id,
                user3_id: nano.user3_id,
                user4_id: nano.user4_id,
                engagement_score: nano.engagement_score || 0,
                last_chat: nano.last_chat,
                emoji: nano.emoji,
                color: nano.color
            }));
        } catch (error) {
            console.error('Failed to fetch nanos:', error);
            return null;
        }
    }

    static async updateNanoColorAndEmoji(nanoId: number, color: string, emoji: string): Promise<boolean> {
        try {
            const response = await fetch(`${API_URL}/update-nano`, {
                method: 'POST',
                headers: this.getHeaders(),
                body: JSON.stringify({ id: nanoId, color, emoji })
            });

            const data = await response.json();
            if (!response.ok) throw new Error('Failed to update nano');

            if (data.panels) {
                await Promise.all(data.panels.map((panel: any) => 
                    PanelService.createPanel(this.mapToPanelRequest(panel))
                ));
            }

            this.addToBalance(Number(localStorage.getItem('userId') || ''), 3);
            return true;
        } catch (error) {
            console.error('Failed to update nano:', error);
            return false;
        }
    }

    static async createCommib(author: string, userId: number, content: string): Promise<boolean> {
        try {
            const response = await fetch(`${API_URL}/create-commib`, {
                method: 'POST',
                headers: this.getHeaders(),
                body: JSON.stringify({ author, user_id: userId, content })
            });

            if (!response.ok) throw new Error('Failed to create commib');
            this.addToBalance(userId, 750);
            return true;
        } catch (error) {
            console.error('Failed to create commib:', error);
            return false;
        }
    }

    static async getCommibsByRecency(offset: number): Promise<CommibResponse[] | null> {
        try {
            const response = await fetch(`${API_URL}/get-commibs-by-recency?offset=${offset}`, {
                method: 'GET',
                headers: this.getHeaders()
            });

            const data = await response.json();
            if (!response.ok) throw new Error('Failed to fetch commibs');

            return data.commibs.map((commib: any) => ({
                ID: commib.ID,
                author: commib.author,
                userId: commib.user_id,
                content: commib.content,
                avgRating: Number(commib.avg_rating),
                views: commib.views
            }));
        } catch (error) {
            console.error('Failed to fetch commibs:', error);
            return null;
        }
    }

    static async updateRating(userId: number, rating: number, commibId: number): Promise<boolean> {
        try {
            const response = await fetch(`${API_URL}/update-rating`, {
                method: 'POST',
                headers: this.getHeaders(),
                body: JSON.stringify({ user_id: userId, rating, commib_id: commibId })
            });

            if (response.status === 400) {
                return false;
            }

            if (!response.ok) {
                throw new Error('Failed to update rating');
            }
            this.addToBalance(userId, 25);
            return true;
        } catch (error) {
            console.error('Failed to update rating:', error);
            throw error;
        }
    }

    static async incrementViewCount(commibId: number): Promise<boolean> {
        try {
            const response = await fetch(`${API_URL}/increment-view-count`, {
                method: 'POST',
                headers: this.getHeaders(),
                body: JSON.stringify({ commib_id: commibId })
            });

            if (!response.ok) throw new Error('Failed to increment view count');
            return true;
        } catch (error) {
            console.error('Failed to increment view count:', error);
            return false;
        }
    }

    static async getRandomCommibForNano(user1Id: number, user2Id: number, user3Id: number, user4Id: number): Promise<CommibResponse | null> {
        try {
            const response = await fetch(
                `${API_URL}/get-random-commib-for-nano?user1_id=${user1Id}&user2_id=${user2Id}&user3_id=${user3Id}&user4_id=${user4Id}`,
                {
                    headers: this.getHeaders()
                }
            );

            const data = await response.json();
            if (!response.ok) throw new Error('Failed to fetch random commib');

            const commib = data.commib;
            return {
                ID: commib.ID,
                author: commib.author,
                userId: commib.user_id,
                content: commib.content,
                avgRating: Number(commib.avg_rating),
                views: commib.views
            };
        } catch (error) {
            console.error('Failed to fetch random commib:', error);
            return null;
        }
    }

    static async getHighestRatedCommibForNano(user1Id: number, user2Id: number, user3Id: number, user4Id: number): Promise<CommibResponse | null> {
        try {
            const response = await fetch(
                `${API_URL}/get-highest-rated-commib-for-nano?user1_id=${user1Id}&user2_id=${user2Id}&user3_id=${user3Id}&user4_id=${user4Id}`,
                {
                    headers: this.getHeaders()
                }
            );

            const data = await response.json();
            if (!response.ok) throw new Error('Failed to fetch highest rated commib');

            const commib = data.commib;
            return {
                ID: commib.ID,
                author: commib.author,
                userId: commib.user_id,
                content: commib.content,
                avgRating: Number(commib.avg_rating),
                views: commib.views
            };
        } catch (error) {
            console.error('Failed to fetch highest rated commib:', error);
            return null;
        }
    }

    static async getChats(nanoId: number, offset: number): Promise<ChatResponse[] | null> {
        try {
            const response = await fetch(
                `${API_URL}/get-chats-by-recency?nano_id=${nanoId}&offset=${offset}`,
                {
                    headers: this.getHeaders()
                }
            );

            const data = await response.json();
            if (!response.ok) throw new Error('Failed to fetch chats');

            return data.chats.map((chat: any) => ChatResponse.fromJson(chat));
        } catch (error) {
            console.error('Failed to fetch chats:', error);
            return null;
        }
    }

    static async createChat(params: {
        author: string;
        userId: number;
        nanoId: number;
        content: string;
        timestamp: string;
        postId?: number;
        commibId?: number;
        replyId?: number;
        replyTo?: string;
        insights?: string;
        analytixId?: number;
        eventId?: number;
    }): Promise<boolean> {
        try {
            const response = await fetch(`${API_URL}/create-chat`, {
                method: 'POST',
                headers: this.getHeaders(),
                body: JSON.stringify({
                    author: params.author,
                    user_id: params.userId,
                    nano_id: params.nanoId,
                    content: params.content,
                    timestamp: params.timestamp,
                    post_id: params.postId,
                    commib_id: params.commibId,
                    reply_id: params.replyId,
                    reply_to: params.replyTo,
                    insights: params.insights,
                    analytix_id: params.analytixId,
                    event_id: params.eventId
                })
            });

            const data = await response.json();
            if (!response.ok) throw new Error('Failed to create chat');
            
            if (data.panels) {
                await Promise.all(data.panels.map((panel: any) => 
                    PanelService.createPanel(this.mapToPanelRequest(panel))
                ));
            }
            
            this.addToBalance(params.userId, 100);
            return true;
        } catch (error) {
            console.error('Failed to create chat:', error);
            return false;
        }
    }

    static async getChatById(chatId: number): Promise<ChatResponse | null> {
        try {
            const response = await fetch(`${API_URL}/get-chat-by-id?id=${chatId}`, {
                headers: this.getHeaders()
            });

            const data = await response.json();
            if (!response.ok) throw new Error('Failed to fetch chat');

            return ChatResponse.fromJson(data.chat);
        } catch (error) {
            console.error('Failed to fetch chat:', error);
            return null;
        }
    }

    static async getCommibById(commibId: number): Promise<CommibResponse | null> {
        try {
            const response = await fetch(`${API_URL}/get-commib-by-id?commib_id=${commibId}`, {
                headers: this.getHeaders()
            });

            const data = await response.json();
            if (!response.ok) throw new Error('Failed to fetch commib');

            const commib = data.commib;
            return {
                ID: commib.ID,
                author: commib.author,
                userId: commib.user_id,
                content: commib.content,
                avgRating: Number(commib.avg_rating),
                views: commib.views
            };
        } catch (error) {
            console.error('Failed to fetch commib:', error);
            return null;
        }
    }

    static async getNanoById(nanoId: number): Promise<NanoResponse | null> {
        try {
            const response = await fetch(`${API_URL}/get-nano-by-id?nano_id=${nanoId}`, {
                headers: this.getHeaders()
            });

            const data = await response.json();
            if (!response.ok) throw new Error('Failed to fetch nano');

            return {
                ID: data.data.ID,
                user1_id: data.data.user1_id,
                user2_id: data.data.user2_id,
                user3_id: data.data.user3_id,
                user4_id: data.data.user4_id,
                engagement_score: data.data.engagement_score,
                last_chat: data.data.last_chat,
                color: data.data.color,
                emoji: data.data.emoji
            };
        } catch (error) {
            console.error('Failed to fetch nano:', error);
            return null;
        }
    }
}
