import { PIMSService } from '../../actions/pims/api-services';
import { AuthService } from '../../actions/auth/api-services';
import { AnalytixService } from '../../actions/analytix/api-services';
import { PIMSMatch, PIMSRequest } from '../../actions/pims/models';
import { UINotifications } from '../../utilities/errors';
import { navigate } from '../../app-router';
import { PlatoChatService } from '../../actions/platochat/api-services';
import { Hash } from '../../utilities/hash';

export class PIMSLoadingContent {
    private element: HTMLElement;
    private progress: number = 0;
    private statusMessage: string = "Startin' 'er up...";
    private topMatches: Array<{
        userData: any;
        distance: number;
        similarTraits: string[];
    }> = [];

    constructor(
        private userId: number,
        private requestingAnalytixId: number | null,
        private filterByLocation: boolean,
        private filterByPersonality: boolean,
        private filterByInterest: boolean,
        private onComplete?: () => void
    ) {
        this.element = document.createElement('div');
        this.initialize();
    }

    private async initialize() {
        this.render();
        await this.runPIMSProcess();
    }

    private async runPIMSProcess() {
        try {
            this.updateStatus("Lemme see what we got here...", 0);
            const userData = await AuthService.getUserById(this.userId);
            if (!userData) {
                this.handleError();
                return;
            }

            this.updateStatus("Just want to see who you've already met", 0.2);
            const excludedUserIdsResponse = await PIMSService.getRetrieveExcludeUsers(this.userId);
            const excludedUserIds = excludedUserIdsResponse?.excludedUserIds || [];
            console.log("Excluded Users:", excludedUserIds);

            this.updateStatus("Using your location choice to find the best matches for you", 0.4);
            const analytixIds = await PIMSService.postUsersByGeohash(
                userData.geohash,
                excludedUserIds,
                this.filterByLocation
            );
            if (!analytixIds) {
                this.handleError();
                return;
            }
            console.log("Analytix IDs:", analytixIds);

            let topiTags: string[] = [];
            if (this.filterByInterest) {
                this.updateStatus("Taking a peek at your interest data", 0.6);
                const tags = await PIMSService.getTopITagsForUserId(this.userId);
                if (tags) {
                    topiTags = tags;
                    console.log("Top iTags:", topiTags);
                }
            }

            this.updateStatus("Running PIMS...", 0.8);
            let matches: PIMSMatch[] | null;

            if (this.filterByPersonality || this.filterByInterest) {
                const pimsRequest: PIMSRequest = {
                    requestingAnalytixId: this.requestingAnalytixId || undefined,
                    analytixIds,
                    iTags: topiTags,
                    filterPersonality: this.filterByPersonality,
                    filterInterests: this.filterByInterest
                };
                matches = await PIMSService.personalityInterestMatchSystem(pimsRequest);
            } else {
                matches = await PIMSService.simpleMatching({
                    requestingUserId: this.userId,
                    analytixIds,
                    iTags: topiTags,
                    filterInterests: false
                });
            }

            if (!matches || matches.length === 0) {
                this.handleError();
                return;
            }

            // Process top matches
            for (const match of matches.slice(0, 3)) {
                const matchUserData = await AuthService.getUserById(match.UserID2);
                if (matchUserData) {
                    this.topMatches.push({
                        userData: matchUserData,
                        distance: Math.round(match.Distance * 100) / 100,
                        similarTraits: match.SimilarTraits || [],
                    });
                }
            }

            if (this.topMatches.length === 0) {
                this.handleError();
                return;
            }

            this.updateStatus("Look at that! You have a new group to talk to", 1.0);
            this.render();

        } catch (error) {
            console.error('PIMS process failed:', error);
            this.handleError();
        }
    }

    private updateStatus(message: string, progress: number) {
        this.statusMessage = message;
        this.progress = progress;
        this.render();
    }

    private handleError() {
        this.updateStatus("Our matching system wasn't able to run. This could be due to lack of users. You can manually create nanos in the nano tab to start chatting.", 1.0);
        UINotifications.shipErrorToUI("Matching system unavailable. You can create nanos in the nano tab to start chatting.", { persist: true });
    }

    private async handleCreateNano() {
        if (this.topMatches.length >= 3) {
            const [match1, match2, match3] = this.topMatches;
            const nanoId = await PlatoChatService.createNano(
                match1.userData.id,
                match2.userData.id,
                match3.userData.id,
                this.userId
            );

            if (nanoId) {
                UINotifications.shipSuccessToUI('Nano created successfully');
                if (this.onComplete) this.onComplete();
                const hashedId = Hash.encodeId(nanoId);
                navigate(`/platochat/nanos/${hashedId}`);
            } else {
                UINotifications.shipErrorToUI('Failed to create nano. Do you have enough points?');
            }
        } else {
            UINotifications.shipErrorToUI("Unfortunately, we couldn't find enough matches for you");
        }
    }

    private render() {
        this.element.innerHTML = `
            <div class="space-y-6">
                <div class="text-xl text-white text-center">${this.statusMessage}</div>
                
                <!-- Progress bar -->
                <div class="h-2 bg-white/10 rounded-full overflow-hidden">
                    <div class="h-full bg-blue-500 transition-all duration-300"
                        style="width: ${this.progress * 100}%">
                    </div>
                </div>

                ${this.progress < 1.0 ? `
                    <div class="text-white/60 text-center">Please wait...</div>
                ` : ''}

                ${this.progress === 1.0 && this.topMatches.length > 0 ? `
                    <div class="space-y-4">
                        ${this.topMatches.map(match => `
                            <div class="bg-white/5 rounded-xl p-4 border border-white/10">
                                <div class="font-bold text-white">${match.userData.name}</div>
                                <div class="text-white/60 text-sm mt-2">
                                    <div>Intro: ${match.userData.intro || 'No intro'}</div>
                                    <div>Location: ${match.userData.location || 'Unknown'}</div>
                                    ${match.distance ? `
                                        <div>Euclidean Distance: ${match.distance}</div>
                                    ` : ''}
                                    ${match.similarTraits.length ? `
                                        <div>Similar Traits: ${match.similarTraits.join(', ')}</div>
                                    ` : ''}
                                </div>
                            </div>
                        `).join('')}

                        <button id="generate-nano" 
                            class="w-full py-2 px-4 bg-blue-500 hover:bg-blue-600 
                            transition-colors rounded-xl text-white font-medium">
                            Generate Nano
                        </button>
                    </div>
                ` : ''}
            </div>
        `;

        // Add event listener for generate nano button
        const generateNanoBtn = this.element.querySelector('#generate-nano');
        generateNanoBtn?.addEventListener('click', () => this.handleCreateNano());
    }

    public getElement(): HTMLElement {
        return this.element;
    }
}