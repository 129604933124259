import { navigate } from '../../app-router';
import { Panel } from '../../actions/your-big-5/models';
import { PanelService } from '../../actions/your-big-5/api-services';
import { ModalForm } from '../reusable/modal-form';
import { AnalytixCollector } from '../analytix/analytix-collector';
import { CreateDiscussionContent } from '../reusable/create-discussion-content';
import { DownloadContent } from '../reusable/download-content';
import { GeolocationContent } from '../auth/geolocation-content';
import { UpdateUserContent } from '../auth/update-user-content';
import { CustomNanoContent } from '../platochat/custom-nano-content';
import { UINotifications } from '../../utilities/errors';
import { Hash } from '../../utilities/hash';
import { AuthContent } from '../auth/auth-content';

export class YourBig5 {
    private element: HTMLElement;
    private onSwitchView: () => void;
    private selectedButton: number = 0;
    private panels: Panel[] = [];
    private iTagPanels: Panel[] = [];

    constructor(onSwitchView: () => void) {
        this.element = document.createElement('div');
        this.onSwitchView = onSwitchView;
        this.initialize();
    }

    private getAppletColor(applet: string): string {
        switch (applet) {
            case 'platochat': return 'bg-blue-600/80';
            case 'kommin': return 'bg-orange-500/80';
            case 'profile': return 'bg-amber-500/80';
            case 'settings': return 'bg-gray-500/80';
            case 'info': return 'bg-pink-500/80';
            case 'ai': return 'bg-emerald-500/80';
            default: return 'bg-white/10';
        }
    }

    private getAppletRoute(applet: string): string {
        switch (applet) {
            case 'ai': return '/ai';
            case 'kommin': return '/kommin';
            case 'platochat': return '/platochat/icebreakers';
            case 'profile': return '/your-profile';
            case 'settings': return '/settings';
            case 'nearby': return '/nearby';
            default: return '/';
        }
    }

    private async initialize(): Promise<void> {
        // Create initial loading state
        this.createLoadingState();

        // Fetch panels
        const userId = parseInt(localStorage.getItem('userId') || '0');
        if (userId) {
            try {
                this.panels = await PanelService.getPanelsByRank(userId);
                console.log('Panels:', this.panels);
            } catch (error) {
                console.error('Failed to fetch panels:', error);
            }
        }

        // Create the actual content
        this.createContent();
    }

    private createLoadingState(): void {
        // Optional: Add a loading spinner or placeholder
        const loadingContainer = document.createElement('div');
        loadingContainer.className = 'container mx-auto mt-12 max-w-xl px-8 py-8';
        loadingContainer.innerHTML = `
            <div class="animate-pulse space-y-4">
                <div class="h-8 bg-white/10 rounded"></div>
                <div class="h-32 bg-white/10 rounded"></div>
                <div class="h-32 bg-white/10 rounded"></div>
            </div>
        `;
        this.element.appendChild(loadingContainer);
    }

    private createContent(): void {
        this.element.innerHTML = '';
        this.element.className = 'h-full w-full flex flex-col overflow-hidden';

        // BETA badge
        const betaBadge = document.createElement('div');
        betaBadge.className = 'self-end';
        betaBadge.innerHTML = `
            <span class="bg-purple-500/80 text-white text-xs font-bold px-2 py-1 rounded-lg border border-white/20 backdrop-blur-md shadow-lg">BETA</span>
        `;

        // Header section with animated gradient
        const header = document.createElement('div');
        header.className = 'text-center mb-4';
        header.innerHTML = `
            <h2 class="text-xl font-bold mb-2">
                <span class="bg-gradient-to-r from-yellow-400 via-amber-500 to-yellow-600 text-transparent bg-clip-text font-extrabold animate-gradient bg-[length:200%_auto]">For You</span>
            </h2>
            <p class="text-sm text-white/70">Your Big <span class="text-purple-400 font-bold">5</span></p>
        `;

        // Add components to element
        this.element.appendChild(betaBadge);
        this.element.appendChild(header);

        // If not logged in, show login prompt instead of panels
        if (!localStorage.getItem('userId')) {
            const loginPrompt = document.createElement('div');
            loginPrompt.className = 'flex-1 flex flex-col items-center justify-center text-center p-6';
            loginPrompt.innerHTML = `
                <div class="
                    bg-gradient-to-br from-black/40 to-black/60
                    p-8 rounded-2xl
                    border border-white/10
                    backdrop-blur-lg
                    flex flex-col gap-6
                    max-w-sm w-full
                    shadow-xl
                ">
                    <h3 class="text-xl font-bold text-white/90">Personalized For You</h3>
                    <p class="text-white/70 text-sm">Sign in to view your notifications and suggestions</p>
                    <button class="
                        px-4 py-2.5
                        bg-gradient-to-br from-violet-500 to-fuchsia-500
                        hover:from-violet-600 hover:to-fuchsia-600
                        text-white font-medium
                        rounded-xl
                        transition-all duration-200
                        border border-white/20
                        shadow-lg
                        flex items-center justify-center gap-2
                    ">
                        <i class="fa-solid fa-right-to-bracket text-white/90"></i>
                        <span>Sign In</span>
                    </button>
                </div>
            `;

            // Add click handler for the sign in button
            const signInButton = loginPrompt.querySelector('button');
            if (signInButton) {
                signInButton.addEventListener('click', () => {
                    const modalForm = new ModalForm();
                    const authContent = new AuthContent();
                    modalForm.append(authContent.getElement());
                    modalForm.mount(document.body);
                    modalForm.open();
                });
            }

            this.element.appendChild(loginPrompt);
        } else {
            // Add existing panels content for logged-in users
            const numbersContainer = document.createElement('div');
            numbersContainer.className = 'flex justify-center gap-2 mb-4';
            
            [1, 2, 3, 4, 5].forEach((num, index) => {
                const circle = document.createElement('div');
                circle.className = `
                    w-8 h-8 rounded-full border border-white/20
                    ${this.selectedButton === index ? this.getAppletColor(this.panels[index]?.Applet) : 'bg-white/10'}
                    backdrop-blur-md flex items-center justify-center
                    text-sm font-bold text-white shadow-lg
                    hover:scale-110 transition-transform duration-200 cursor-pointer
                `;
                circle.textContent = num.toString();
                circle.addEventListener('click', () => {
                    this.selectedButton = index;
                    this.updateSelection(this.panels);
                });
                numbersContainer.appendChild(circle);
            });

            const blipsContainer = document.createElement('div');
            blipsContainer.className = 'space-y-2 flex-1 overflow-y-auto min-h-0';

            this.panels.forEach((panel: Panel, index) => {
                const blipElement = document.createElement('button');
                const appletColor = this.getAppletColor(panel.Applet);
                
                blipElement.className = `
                    w-full p-3 rounded-xl border border-white/20
                    ${this.selectedButton === index ? appletColor : 'bg-white/10'}
                    backdrop-blur-md text-white transition-all duration-300
                    hover:scale-[1.02] shadow-lg text-left
                    flex items-center gap-2
                    ${this.selectedButton === index ? '' : `hover:${appletColor}`}
                `;
                
                blipElement.innerHTML = `
                    <div class="w-6 h-6 rounded-full bg-white/10 backdrop-blur-md flex items-center justify-center font-bold shrink-0">
                        ${index + 1}
                    </div>
                    <div class="min-w-0 flex-1">
                        <div class="font-medium text-sm truncate">${panel.Title}</div>
                        <div class="text-xs text-white/70 truncate">${panel.Content}</div>
                    </div>
                    <div class="flex-shrink-0">
                        <div class="w-6 h-6 rounded-full bg-black/60 backdrop-blur-md border border-white/20 flex items-center justify-center">
                            <svg class="w-3 h-3 ${panel.Persist ? 'text-white shadow-glow' : 'text-white/40'}" viewBox="0 0 20 20" fill="currentColor">
                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                            </svg>
                        </div>
                    </div>
                `;
                
                // Modify the persist icon click handler
                const persistIcon = blipElement.querySelector('svg');
                if (persistIcon) {
                    persistIcon.addEventListener('click', async (e) => {
                        e.stopPropagation();
                        let success;
                        
                        if (panel.Persist) {
                            success = await PanelService.unpersistPanel(panel.ID);
                            if (success) {
                                persistIcon.classList.remove('text-white', 'shadow-glow');
                                persistIcon.classList.add('text-white/40');
                                panel.Persist = false;
                            }
                        } else {
                            success = await PanelService.persistPanel(panel);
                            if (success) {
                                persistIcon.classList.remove('text-white/40');
                                persistIcon.classList.add('text-white', 'shadow-glow');
                                panel.Persist = true;
                            }
                        }
                    });
                }
                
                blipElement.addEventListener('click', () => {
                    this.handlePanelClick(panel);
                });

                blipsContainer.appendChild(blipElement);
            });

            this.element.appendChild(numbersContainer);
            this.element.appendChild(blipsContainer);
        }
    }

    private updateSelection(panels: Omit<Panel, 'ID' | 'UserId'>[]): void {
        const buttons = this.element.querySelectorAll('button');
        const circles = this.element.querySelectorAll('.rounded-full');

        buttons.forEach((btn, idx) => {
            if (idx < 5) {
                btn.className = btn.className.replace(
                    /(bg-white\/10|bg-blue-600\/80|bg-orange-500\/80|bg-amber-500\/80|bg-gray-500\/80|bg-pink-500\/80|bg-emerald-500\/80)/g,
                    idx === this.selectedButton ? this.getAppletColor(panels[idx].Applet) : 'bg-white/10'
                );
            }
        });

        circles.forEach((circle, idx) => {
            circle.className = circle.className.replace(
                /(bg-white\/10|bg-blue-600\/80|bg-orange-500\/80|bg-amber-500\/80|bg-gray-500\/80|bg-pink-500\/80|bg-emerald-500\/80)/g,
                idx === this.selectedButton ? this.getAppletColor(panels[idx].Applet) : 'bg-white/10'
            );
        });
    }

    public mount(parent: HTMLElement): void {
        parent.appendChild(this.element);
    }

    public unmount(): void {
        this.element.remove();
    }

    private handlePanelClick(panel: Panel): void {
        if (panel.Modal && !panel.Route) {
            const modalForm = new ModalForm();
            modalForm.mount(document.body);
            
            switch (panel.Modal) {
                case 'customNano':
                    const customNanoContent = new CustomNanoContent((success) => {
                        if (success) {
                            modalForm.close();
                        }
                    });
                    modalForm.append(customNanoContent.getElement());
                    modalForm.open();
                    break;
                case 'analytixCollector':
                    const userId = parseInt(localStorage.getItem('userId') || '0');
                    const analytixCollector = new AnalytixCollector(userId, (success, result) => {
                        if (success) {
                            modalForm.close();
                        }
                    });
                    modalForm.append(analytixCollector.getElement());
                    modalForm.open();
                    break;
                case 'createDiscussion':
                    const createDiscussion = new CreateDiscussionContent();
                    createDiscussion.createDiscussionForm().then(element => {
                        modalForm.append(element);
                        modalForm.open();
                    });
                    break;
                case 'downloadContent':
                    const downloadContent = new DownloadContent();
                    modalForm.append(downloadContent.getElement());
                    modalForm.open();
                    break;
                case 'geolocationContent':
                    const geoUserId = parseInt(localStorage.getItem('userId') || '0');
                    const geolocationContent = new GeolocationContent(geoUserId, (success) => {
                        if (success) {
                            modalForm.close();
                        }
                    });
                    modalForm.append(geolocationContent.getElement());
                    modalForm.open();
                    break;
                case 'updateUserContent':
                    const userData = JSON.parse(localStorage.getItem('userData') || 'null');
                    const updateUserContent = new UpdateUserContent(userData, () => {
                        modalForm.close();
                    });
                    modalForm.append(updateUserContent.getElement());
                    modalForm.open();
                    break;
            }
        } else {
            if (panel.Route === '/dashboard' && panel.Applet) {
                localStorage.setItem('chosenApplet', panel.Applet);
                window.location.href = this.getAppletRoute(panel.Applet);
            } else {
                const match = panel.Route.match(/\/kommin\/discussions\/hashedId\((\d+)\)/);
                if (match) {
                    const id = parseInt(match[1]);
                    const hashedId = Hash.encodeId(id);
                    const route = panel.Route.replace(/\/kommin\/discussions\/hashedId\(\d+\)/, `/kommin/discussions/${hashedId}`);
                    console.log('Navigating to:', route);
                    window.location.href = route;
                } else {
                    localStorage.setItem('chosenApplet', panel.Applet);
                    window.location.href = panel.Route;
                }
            }
        }
    }

    public getElement(): HTMLElement {
        return this.element;
    }
}