import { AuthContent } from '../auth/auth-content';
import { ArticleContent } from '../tutorial/article-content';
import { DownloadContent } from './download-content';
import { ModalForm } from './modal-form';
import { navigate } from '../../app-router.ts';

export class TopBar {
    private container!: HTMLDivElement;
    private logo!: HTMLImageElement;
    private authButton!: HTMLDivElement;
    private helpButton!: HTMLDivElement;
    private authContent!: AuthContent;
    private articleContent!: ArticleContent;
    private downloadContent!: DownloadContent;
    private downloadButton!: HTMLDivElement;

    constructor(private modalForm: ModalForm) {
        this.authContent = new AuthContent();
        this.articleContent = new ArticleContent();
        this.downloadContent = new DownloadContent();
        this.initializeContainer();
        this.initializeLogo();
        this.initializeButtons();
    }

    private initializeContainer(): void {
        this.container = document.createElement('div');
        this.container.style.cssText = `
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 12px 24px;
            background-color: transparent;
            width: 100%;
            margin-top: 20px;
            box-sizing: border-box;
            height: 70px;
        `;
    }

    private initializeLogo(): void {
        this.logo = document.createElement('img');
        this.logo.className = `
            h-8
            w-auto
            cursor-pointer
            transition-opacity
            hover:opacity-80
        `;
        
        // Set initial logo based on viewport
        this.updateLogoSource();
        
        // Add resize listener to switch logos
        window.addEventListener('resize', () => this.updateLogoSource());
        
        // Always navigate to home
        this.logo.onclick = () => navigate('/');

        this.container.appendChild(this.logo);
    }

    private updateLogoSource(): void {
        if (window.innerWidth < 768) { // md breakpoint in Tailwind
            this.logo.src = '/icon.png';
            this.logo.className = `
            h-12
            w-auto
            cursor-pointer
            transition-all
            hover:opacity-80
            rounded-xl
            shadow-lg
            hover:shadow-xl
            hover:scale-105
            `;
        } else {
            this.logo.src = '/logo.png';
            this.logo.className = `
                h-8
                w-auto
                cursor-pointer
                transition-opacity
                hover:opacity-80
            `;
        }
    }

    private initializeButtons(): void {
        const rightSection = document.createElement('div');
        rightSection.className = 'nav-controls';
        rightSection.style.cssText = `
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            gap: 0.8rem;
            background: rgba(15, 15, 15, 0.7);
            backdrop-filter: blur(10px);
            padding: 0.8rem 1rem;
            border-radius: 12px;
            border: 1px solid rgba(255, 255, 255, 0.1);
        `;

        // Features Button
        const featuresButton = document.createElement('button');
        featuresButton.className = 'features-btn';
        featuresButton.textContent = 'FEATURES';
        featuresButton.onclick = () => {
            navigate('/features');
        };

        // Icon buttons container
        const iconButtons = document.createElement('div');
        iconButtons.style.cssText = `
            display: flex;
            gap: 1.5rem;
            align-items: center;
        `;

        // Download App Button
        this.downloadButton = document.createElement('div');
        this.downloadButton.className = 'nav-icon';
        this.downloadButton.innerHTML = '<i class="fa-solid fa-mobile-screen"></i>';
        this.downloadButton.title = 'Download the App';
        this.downloadButton.onclick = () => {
            this.modalForm.append(this.downloadContent.getElement());
            this.modalForm.open();
        };

        // Help Button
        this.helpButton = document.createElement('div');
        this.helpButton.className = 'nav-icon';
        this.helpButton.innerHTML = '<i class="fa-solid fa-circle-question"></i>';
        this.helpButton.title = 'Learn About PLATO5';
        this.helpButton.onclick = () => {
            this.modalForm.append(this.articleContent.getElement());
            this.modalForm.open();
        };

        // Auth Button
        this.authButton = document.createElement('div');
        this.authButton.className = 'nav-icon';
        this.authButton.innerHTML = '<i class="fa-solid fa-user-circle"></i>';
        this.authButton.title = 'Login or Signup';
        this.authButton.onclick = () => {
            this.modalForm.append(this.authContent.getElement());
            this.modalForm.open();
        };

        // Assemble everything
        iconButtons.appendChild(this.downloadButton);
        iconButtons.appendChild(this.helpButton);
        iconButtons.appendChild(this.authButton);

        rightSection.appendChild(featuresButton);
        rightSection.appendChild(iconButtons);

        this.container.appendChild(rightSection);
    }

    public mount(parent: HTMLElement): void {
        parent.appendChild(this.container);
    }

    public getElement(): HTMLDivElement {
        return this.container;
    }

    public unmount(): void {
        this.container.remove();
    }
}