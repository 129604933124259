import { ModalForm } from '../reusable/modal-form';
import { AuthContent } from '../auth/auth-content';

export class NavColumn {
    private container: HTMLElement;
    private currentApplet: string;
    private onAppletChange?: (applet: string) => void;

    constructor() {
        this.container = document.createElement('div');
        this.container.className = `
            w-[100px] lg:w-72 
            flex flex-col 
            h-screen mt-2 mx-auto lg:mx-2
            border-r-2
            animated-border
        `;
        
        // Add the animated gradient border styles
        const style = document.createElement('style');
        style.textContent = `
            .animated-border {
                border-image: linear-gradient(to bottom, rgb(168 85 247 / 0.2), rgb(249 115 22 / 0.2), rgb(168 85 247 / 0.2)) 1;
                animation: gradient-move 3s infinite alternate;
            }

            @keyframes gradient-move {
                0% {
                    border-image-source: linear-gradient(to bottom, rgb(168 85 247 / 0.2), rgb(249 115 22 / 0.2), rgb(168 85 247 / 0.2));
                }
                100% {
                    border-image-source: linear-gradient(to top, rgb(168 85 247 / 0.2), rgb(249 115 22 / 0.2), rgb(168 85 247 / 0.2));
                }
            }
        `;
        document.head.appendChild(style);
        
        const savedApplet = localStorage.getItem('chosenApplet') || 'home';
        const validApplets = ['home', 'nanos', 'kommin', 'nearby', 'profile', 'ai', 'icebreakers', 'settings'];
        this.currentApplet = validApplets.includes(savedApplet) ? savedApplet : 'home';
        
        this.initializeLayout();
    }

    private initializeLayout(): void {
        // Logo section - centered in narrow view
        const logoSection = document.createElement('div');
        logoSection.className = 'p-4 flex justify-center items-center';
        logoSection.innerHTML = `
            <img src="/icon.png" class="h-12 w-12 rounded-xl shadow-lg hover:shadow-xl hover:scale-105 transition-all cursor-pointer lg:hidden" alt="Icon">
            <img src="/logo.png" class="h-6 w-auto hidden lg:block cursor-pointer transition-opacity hover:opacity-80" alt="Logo">
        `;
        
        // Make logo clickable to go home
        logoSection.querySelector('img')?.addEventListener('click', () => {
            window.location.href = '/';
        });
        
        this.container.appendChild(logoSection);

        // Add Join/Login button for non-logged in users right after logo
        const userId = localStorage.getItem('userId');
        if (!userId) {
            const joinButton = document.createElement('div');
            joinButton.className = 'px-4 pt-4';
            joinButton.innerHTML = `
                <button class="
                    w-full
                    h-12
                    bg-gradient-to-r from-blue-500 to-blue-600
                    hover:from-blue-600 hover:to-blue-700
                    text-white
                    rounded-xl
                    text-sm
                    font-medium
                    transition-all
                    duration-200
                    flex
                    items-center
                    justify-center
                    gap-2
                    shadow-lg
                    shadow-blue-500/20
                ">
                    <i class="fas fa-user-plus text-white text-base"></i>
                    <span class="hidden lg:inline">Join PLATO5</span>
                </button>
            `;

            // Add click handler to open auth modal
            joinButton.querySelector('button')?.addEventListener('click', () => {
                const modalForm = new ModalForm();
                const authContent = new AuthContent();
                modalForm.append(authContent.getElement());
                modalForm.mount(document.body);
                modalForm.open();
            });

            this.container.appendChild(joinButton);
        }

        // Navigation section - centered icons in narrow view
        const navSection = document.createElement('div');
        navSection.className = 'flex flex-col flex-1 min-h-0 overflow-y-auto';
        
        const buttonsContainer = document.createElement('div');
        buttonsContainer.className = 'py-4 px-3 lg:px-4 flex flex-col gap-2';
        
        // Update nav items to be centered in narrow view with perfect squares
        buttonsContainer.innerHTML = `
            <a href="/" data-applet="home" class="group flex items-center justify-center lg:justify-start gap-3 p-3">
                <div class="w-12 h-12 flex items-center justify-center rounded-xl bg-pink-500/10 group-hover:bg-pink-500/20 transition-all border border-pink-500/20">
                    <svg class="w-6 h-6 text-pink-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path>
                    </svg>
                </div>
                <span class="text-sm text-pink-400 hidden lg:inline">Home / Matching</span>
            </a>

            <a href="/platochat/nanos" data-applet="nanos" class="group flex items-center justify-center lg:justify-start gap-3 p-3">
                <div class="w-12 h-12 flex items-center justify-center rounded-xl bg-blue-500/10 group-hover:bg-blue-500/20 transition-all border border-blue-500/20">
                    <svg class="w-6 h-6 text-blue-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"></path>
                    </svg>
                </div>
                <span class="text-sm text-blue-400 hidden lg:inline">Nanos</span>
            </a>

            <a href="/kommin" data-applet="kommin" class="group flex items-center justify-center lg:justify-start gap-3 p-3">
                <div class="w-12 h-12 flex items-center justify-center rounded-xl bg-orange-500/10 group-hover:bg-orange-500/20 transition-all border border-orange-500/20">
                    <span class="text-xl font-bold text-orange-400">K</span>
                </div>
                <span class="text-sm text-orange-400 hidden lg:inline">Kommin</span>
            </a>

            <a href="/nearby" data-applet="nearby" class="group flex items-center justify-center lg:justify-start gap-3 p-3">
                <div class="w-12 h-12 flex items-center justify-center rounded-xl bg-purple-500/10 group-hover:bg-purple-500/20 transition-all border border-purple-500/20">
                    <svg class="w-6 h-6 text-purple-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"></path>
                    </svg>
                </div>
                <span class="text-sm text-purple-400 hidden lg:inline">Nearby</span>
            </a>

            <a href="/your-profile" data-applet="profile" class="group flex items-center justify-center lg:justify-start gap-3 p-3">
                <div class="w-12 h-12 flex items-center justify-center rounded-xl bg-yellow-500/10 group-hover:bg-yellow-500/20 transition-all border border-yellow-500/20">
                    <svg class="w-6 h-6 text-yellow-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"></path>
                    </svg>
                </div>
                <span class="text-sm text-yellow-400 hidden lg:inline">Profile</span>
            </a>

            <a href="/ai" data-applet="ai" class="group flex items-center justify-center lg:justify-start gap-3 p-3">
                <div class="w-12 h-12 flex items-center justify-center rounded-xl bg-emerald-500/10 group-hover:bg-emerald-500/20 transition-all border border-emerald-500/20">
                    <span class="text-lg font-mono font-bold text-emerald-400">Ai</span>
                </div>
                <span class="text-sm text-emerald-400 hidden lg:inline">AI Assistants</span>
            </a>

            <a href="/platochat/icebreakers" data-applet="icebreakers" class="group flex items-center justify-center lg:justify-start gap-3 p-3">
                <div class="w-12 h-12 flex items-center justify-center rounded-xl bg-sky-500/10 group-hover:bg-sky-500/20 transition-all border border-sky-500/20">
                    <i class="fa-solid fa-snowflake text-lg text-sky-400"></i>
                </div>
                <span class="text-sm text-sky-400 hidden lg:inline">Icebreakers</span>
            </a>

            <a href="/settings" data-applet="settings" class="group flex items-center justify-center lg:justify-start gap-3 p-3">
                <div class="w-12 h-12 flex items-center justify-center rounded-xl bg-gray-500/10 group-hover:bg-gray-500/20 transition-all border border-gray-500/20">
                    <svg class="w-6 h-6 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"></path>
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path>
                    </svg>
                </div>
                <span class="text-sm text-gray-400 hidden lg:inline">Settings</span>
            </a>
        `;

        buttonsContainer.querySelectorAll('a').forEach(link => {
            const applet = link.getAttribute('data-applet');
            if (applet === this.currentApplet) {
                link.classList.add('bg-white/5', 'rounded-xl', 'ring-1', 'ring-white/10', 'shadow-lg', 'shadow-black/5');
            }
        });

        navSection.appendChild(buttonsContainer);
        this.container.appendChild(navSection);
    }

    public onSelect(callback: (applet: string) => void) {
        this.onAppletChange = callback;
    }

    public mount(parent: HTMLElement): void {
        parent.appendChild(this.container);
    }

    public unmount(): void {
        this.container.remove();
    }

    public getElement(): HTMLElement {
        return this.container;
    }
}