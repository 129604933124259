import { UINotifications } from '../../utilities/errors';
import { Panel, CreatePanelRequest } from './models';
import { AuthService } from '../auth/api-services';
import { defaultPanels } from './models';
import { Hash } from '../../utilities/hash';

const AUTH_API_URL = import.meta.env.VITE_AUTH_API_URL
const KOMMIN_API_URL = import.meta.env.VITE_KOMMIN_API_URL
const API_KEY = import.meta.env.VITE_API_KEY

export class PanelService {
    static async createPanel(request: CreatePanelRequest): Promise<Panel | null> {
        try {
            const sessionData = AuthService.getSessionData();
            if (!sessionData) {
                throw new Error('No session data found');
            }

            const response = await fetch(`${AUTH_API_URL}/create-panel`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-Key': API_KEY,
                    'X-Session-ID': sessionData.session_id,
                    'X-Public-Key': sessionData.public_key,
                    'X-User-ID': sessionData.userId?.toString() || ''
                },
                mode: 'cors',
                credentials: 'omit',
                body: JSON.stringify(request),
            });

            if (!response.ok) {
                throw new Error('Failed to create panel');
            }

            return response.json();
        } catch (error) {
            console.error('Create panel error:', error);
            return null;
        }
    }

    static async getPanelsByRank(userId: number): Promise<Panel[]> {
        try {
            const sessionData = AuthService.getSessionData();
            if (!sessionData) {
                throw new Error('No session data found');
            }

            // Get iTag panels first
            await PanelService.getTopITagPanels(userId);

            const response = await fetch(`${AUTH_API_URL}/get-panels-by-rank?userId=${userId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-Key': API_KEY,
                    'X-Session-ID': sessionData.session_id,
                    'X-Public-Key': sessionData.public_key,
                    'X-User-ID': sessionData.userId?.toString() || ''
                },
                mode: 'cors',
                credentials: 'omit'
            });

            if (!response.ok) {
                throw new Error('Failed to fetch panels');
            }

            return response.json();
        } catch (error) {
            console.error('Get panels error:', error);
            UINotifications.shipErrorToUI('Failed to fetch panels');
            return [];
        }
    }

    static async reducePanel(panelId: number): Promise<boolean> {
        try {
            const sessionData = AuthService.getSessionData();
            if (!sessionData) {
                throw new Error('No session data found');
            }

            const response = await fetch(`${AUTH_API_URL}/reduce-panel?panelId=${panelId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-Key': API_KEY,
                    'X-Session-ID': sessionData.session_id,
                    'X-Public-Key': sessionData.public_key,
                    'X-User-ID': sessionData.userId?.toString() || ''
                },
                mode: 'cors',
                credentials: 'omit'
            });

            if (!response.ok) {
                throw new Error('Failed to reduce panel points');
            }

            return true;
        } catch (error) {
            console.error('Reduce panel error:', error);
            UINotifications.shipErrorToUI('Failed to reduce panel points');
            return false;
        }
    }

    static async persistPanel(panel: Panel): Promise<boolean> {
        try {
            const sessionData = AuthService.getSessionData();
            if (!sessionData) {
                throw new Error('No session data found');
            }

            console.log('Persisting panel:', panel);
            
            const url = panel.ID === 0 ? 
                `${AUTH_API_URL}/persist-default-panel?title=${encodeURIComponent(panel.Title)}` : 
                `${AUTH_API_URL}/persist-panel?panelId=${panel.ID}`;

            console.log('Using URL:', url);  // Debug the URL being used

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-Key': API_KEY,
                    'X-Session-ID': sessionData.session_id,
                    'X-Public-Key': sessionData.public_key,
                    'X-User-ID': sessionData.userId?.toString() || ''
                },
                mode: 'cors',
                credentials: 'omit'
            });

            if (!response.ok) {
                throw new Error('Failed to persist panel');
            }

            return true;
        } catch (error) {
            console.error('Persist panel error:', error);
            UINotifications.shipErrorToUI('Failed to persist panel');
            return false;
        }
    }

    static async unpersistPanel(panelId: number): Promise<boolean> {
        try {
            const sessionData = AuthService.getSessionData();
            if (!sessionData) {
                throw new Error('No session data found');
            }

            const response = await fetch(`${AUTH_API_URL}/unpersist-panel?panelId=${panelId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-Key': API_KEY,
                    'X-Session-ID': sessionData.session_id,
                    'X-Public-Key': sessionData.public_key,
                    'X-User-ID': sessionData.userId?.toString() || ''
                },
                mode: 'cors',
                credentials: 'omit'
            });

            if (!response.ok) {
                throw new Error('Failed to unpersist panel');
            }

            return true;
        } catch (error) {
            console.error('Unpersist panel error:', error);
            UINotifications.shipErrorToUI('Failed to unpersist panel');
            return false;
        }
    }

    static async getTopITagPanels(userId: number): Promise<Panel[]> {
        try {
            const sessionData = AuthService.getSessionData();
            if (!sessionData) {
                throw new Error('No session data found');
            }

            const response = await fetch(`${KOMMIN_API_URL}/get-top-itag-panels?user_id=${userId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-Key': API_KEY,
                    'X-Session-ID': sessionData.session_id,
                    'X-Public-Key': sessionData.public_key,
                    'X-User-ID': sessionData.userId?.toString() || ''
                },
                mode: 'cors',
                credentials: 'omit'
            });

            if (!response.ok) {
                throw new Error('Failed to fetch iTag panels');
            }

            const data = await response.json();
            const panels = await Promise.all((data.panels || []).map((panel: { 
                user_id: number;
                title: string;
                content: string;
                modal: string;
                route: string;
                points: number;
                applet: string;
            }) => {
                // Hash the ID in the route if it exists
                let route = panel.route;
                const match = route.match(/hashedId\((\d+)\)/);
                if (match) {
                    const id = parseInt(match[1]);
                    const hashedId = Hash.encodeId(id);
                    route = route.replace(/hashedId\(\d+\)/, hashedId);
                }
                
                return PanelService.createPanel({
                    userId: panel.user_id,
                    title: panel.title,
                    content: panel.content,
                    modal: panel.modal,
                    route: route,  // Use the modified route
                    points: panel.points,
                    applet: panel.applet
                });
            }));

            return panels.filter((panel): panel is Panel => panel !== null);
            
        } catch (error) {
            console.error('Get iTag panels error:', error);
            return [];
        }
    }
}
